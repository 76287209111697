import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import ContentContainer from '../../layout/ContentContainer';
import LicensorForm from './LicensorForm';

import licensorsService from '../../../services/licensorServices';
import { getNotEmptyFields } from '../../../utils/objectUtils';
import {
  addLicensorStatuses,
  LicensorStatus,
  licensorStatusOptions,
  ILicensor,
  LicensorType,
  TEMP_ILicensorDTO,
} from '../../../types/licensorTypes';
import { ISelectOption } from '../../common/inputs/inputTypes';
import { showServerErrors } from '../../../utils/errorsUtils';
import { Country } from '../../../utils/constants/countires';

const AddLicensor: React.FC = () => {
  const { goBack } = useHistory();
  const newLicensor: ILicensor = {
    id: '',
    name: '',
    label: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    logoFile: null,
    webPage: '',
    status: LicensorStatus.Unverified,
    address: {
      city: '',
      country: Country.Poland,
      postCode: '',
      street: '',
    },
    financialData: {
      type: LicensorType.Private,
      representiveInstanceName: '',
      identifier: '',
      accountNumber: '',
      taxOffice: '',
      costOfObtainingIncomePercent: 0,
      taxPercent: 0,
    },
    patron: null,
    certificateOfResidence: null,
    certificateValidFrom: '',
    certificateValidTo: '',
    contracts: '',
  };

  const statusOptions: Array<ISelectOption> = addLicensorStatuses.map((key) => licensorStatusOptions[key]);

  const handleSubmit = async (licensorData: TEMP_ILicensorDTO) => {
    try {
      const { adress, ...restOfData } = licensorData;
      const formatted = { address: adress, ...restOfData };
      const newLicensor = {
        ...getNotEmptyFields(formatted),
      };
      await licensorsService.add(newLicensor);
      toast.success(`Dodano nowego artystę!`);
      goBack();
    } catch (errors: any) {
      showServerErrors(errors);
    }
  };

  return (
    <ContentContainer title='Dodaj wydawcę'>
      <LicensorForm licensor={newLicensor} statusOptions={statusOptions} onSubmit={handleSubmit} isEdit={false} />
    </ContentContainer>
  );
};

export default AddLicensor;
