import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import ContentContainer from 'components/layout/ContentContainer';

import digitalReleasesService from 'services/digitalReleaseService';
import { IDigitalRelease, DigitalReleaseRequest } from 'types/digitalReleaseTypes';
import { getNotEmptyFields } from 'utils/objectUtils';
import { showServerErrors } from 'utils/errorsUtils';

import DigitalReleaseForm from './DigitalReleaseForm';
import { useTranslation } from 'react-i18next';

const EditDigitalRelease: React.FC = () => {
  const [digitalRelease, setDigitalRelease] = useState<IDigitalRelease | null>(null);
  const { goBack } = useHistory();
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();

  const handleSubmit = async (digitalRelease: DigitalReleaseRequest) => {
    try {
      const newDigitalRelease = {
        ...getNotEmptyFields<DigitalReleaseRequest>(digitalRelease),
      };
      await digitalReleasesService.edit(newDigitalRelease);
      toast.success(t('digitalRelease.toast.edited'));
      goBack();
    } catch (errors: any) {
      showServerErrors(errors);
    }
  };

  useEffect(() => {
    digitalReleasesService
      .get(id)
      .then((digitalReleaseData) => {
        if (digitalReleaseData) {
          setDigitalRelease(digitalReleaseData);
        }
      })
      .catch((err) => {
        console.error({ err });
      });
  }, [id]);

  return (
    digitalRelease && (
      <ContentContainer title={t('digitalRelease.title.edit')}>
        <DigitalReleaseForm digitalRelease={digitalRelease} onSubmit={handleSubmit} isEdit={true} />
      </ContentContainer>
    )
  );
};

export default EditDigitalRelease;
