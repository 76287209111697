import { Form, Formik } from 'formik';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import { RootState } from 'store/store';
import { useTranslation } from 'react-i18next';

import DeleteButton from 'components/common/buttons/deleteButton/DeleteButton';
import FormSection from 'components/common/forms/FormSection';
import SelectInput from 'components/common/inputs/select/Select';
import SubmitButton from 'components/common/buttons/submitButton/SubmitButton';
import TextField from 'components/common/inputs/textInput/TextField';

import { IDigitalRelease, DigitalReleaseRequest } from 'types/digitalReleaseTypes';

import digitalReleasesService from 'services/digitalReleaseService';
import { showServerErrors } from 'utils/errorsUtils';
import {
  digitalReleaseValidation,
  formatDataToRequest,
  getStatusOptions,
  getTypeOptions,
} from 'components/digitalRelease/utils/helpers';
import Datepicker from 'components/common/inputs/Datepicker/DatepickerInput';
import FileField from 'components/common/inputs/FileInput/FileField';
import Switch from 'components/common/inputs/switch/Switch';
import ArtistPropList from 'components/artists/propList/ArtistPropList';
import ImageField from 'components/common/inputs/imageInput/ImageField';
import ProviderSelect from './ProvidersSelect';

interface IEditDigitalReleaseProps {
  digitalRelease: IDigitalRelease;
  isEdit: boolean;
  onSubmit: (values: DigitalReleaseRequest) => void;
}

const statusOptions = getStatusOptions();
const typeOptions = getTypeOptions();

const DigitalReleaseForm: React.FC<IEditDigitalReleaseProps> = ({ digitalRelease, isEdit, onSubmit }) => {
  const { t } = useTranslation();
  const permissions = useSelector((state: RootState) => state.userPermissions);
  const { replace } = useHistory();

  if (!permissions) {
    return null;
  }

  const handleSubmit = async (values: IDigitalRelease) => {
    const data = formatDataToRequest(values);
    await onSubmit(data);
  };

  const handleDelete = async () => {
    try {
      await digitalReleasesService.remove(digitalRelease.id);
      toast.success(t('digitalRelease.toast.removed'));
      replace('/digitalReleases');
    } catch (errors: any) {
      showServerErrors(errors);
    }
  };

  return (
    <Formik
      initialValues={digitalRelease}
      validationSchema={digitalReleaseValidation}
      onSubmit={handleSubmit}
      validateOnMount
    >
      {({ errors, isSubmitting, values, initialValues, setFieldValue }) => (
        <Form className='flex flex-col gap-x-6 mx-auto  px-24 lg:px-36 pb-36 xl:gap-x-10 bg-white-dirty bg-opacity-70 border-t border-gray border-opacity-40 pt-30'>
          <FormSection isDisabled={isSubmitting} label={t('digitalRelease.coverFile')}>
            <ImageField name='coverFile' className='mx-auto md:mx-0 mb-8' imgSrc={values.coverFile} />
          </FormSection>

          <FormSection isDisabled={isSubmitting} label={t('labels.basicData')}>
            <TextField name='title' label={t('labels.title')} helperText={errors.title} />
            <TextField name='vesion' label={t('labels.version')} helperText={errors.vesion} />
            <TextField name='ean' label='EAN' helperText={errors.ean} />
            <TextField name='pLine' label='(P)' placeholder={errors.pLine} />
            <TextField name='cLine' label='&copy;' placeholder={errors.cLine} />
          </FormSection>

          <FormSection isDisabled={isSubmitting} label={t('labels.basicData', 'Dane podstawowe')}>
            <TextField
              name='collectiveManager'
              label={t('digitalRelease.collectiveManager')}
              helperText={errors.collectiveManager}
            />
            <Datepicker name='releaseDate' label={t('labels.releaseDate')} helperText={errors.releaseDate} />
            <SelectInput
              name='plates'
              items={[]}
              label={t('digitalRelease.plates')}
              defaultValue={initialValues.plates}
            />
            <ProviderSelect defaultValue={values.labels[0]?.labelId || ''} />
          </FormSection>

          <FormSection isDisabled={isSubmitting} label={t('labels.authors')}>
            <ArtistPropList
              dataService={digitalReleasesService}
              defaultValue={initialValues.artists}
              errorMessage={values.artists.length === 0 ? errors.artists?.toString() : ''}
              onChange={(selected) => {
                setFieldValue(
                  'artists',
                  selected.map((artist) => ({ artistId: artist.id, artistRole: artist.role }))
                );
              }}
            />
          </FormSection>
          <FormSection isDisabled={isSubmitting} label={t('labels.album', 'Album')}>
            <SelectInput name='type' items={typeOptions} label={t('labels.type')} defaultValue={initialValues.type} />
            <TextField name='genre' label={t('digitalRelease.genre')} helperText={errors.genre} />
            <SelectInput
              name='status'
              items={statusOptions}
              label={t('labels.status')}
              defaultValue={initialValues.status}
            />
            <Switch name='processingFlag' label={t('digitalRelease.processingFlag')} />
          </FormSection>

          <div className='flex gap-x-2'>
            <SubmitButton isSubmitting={isSubmitting} className='mt-6 '>
              Zapisz
            </SubmitButton>

            {isEdit && (
              <DeleteButton isSubmitting={isSubmitting} disabled={!permissions.Artist.Delete} onClick={handleDelete}>
                {/* TODO: <DeleteButton isSubmitting={isSubmitting} disabled={!permissions.IDigitalRelease.Delete} onClick={handleDelete}> */}
                Usuń
              </DeleteButton>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default DigitalReleaseForm;
