import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import ContentContainer from '../../layout/ContentContainer';
import LicensorForm from './LicensorForm';

import licensorsService from '../../../services/licensorServices';
import { getNotEmptyFields } from '../../../utils/objectUtils';
import { showServerErrors } from '../../../utils/errorsUtils';
import { ISelectOption } from '../../common/inputs/inputTypes';
import {
  licensorStatusOptions,
  editLicensorStatuses,
  ILicensor,
  TEMP_ILicensorDTO,
} from '../../../types/licensorTypes';

const EditLicensor: React.FC = () => {
  const [licensor, setLicensor] = useState<ILicensor | null>(null);
  const { goBack } = useHistory();
  const { id } = useParams<{ id: string }>();

  const statusOptions: Array<ISelectOption> = editLicensorStatuses.map((key) => licensorStatusOptions[key]);

  const handleSubmit = async (licensor: TEMP_ILicensorDTO) => {
    try {
      const newLicensor = {
        ...getNotEmptyFields(licensor),
      };
      await licensorsService.edit(newLicensor);
      toast.success(`Poprawnie edytowano artystę!`);
      goBack();
    } catch (errors: any) {
      showServerErrors(errors);
    }
  };

  useEffect(() => {
    licensorsService
      .get(id)
      .then((licensorData) => {
        if (licensorData) {
          setLicensor(licensorData);
        }
      })
      .catch((err) => {
        console.error({ err });
      });
  }, [id]);

  return (
    licensor && (
      <ContentContainer title='Edycja wydawcy'>
        <LicensorForm licensor={licensor} onSubmit={handleSubmit} statusOptions={statusOptions} isEdit={true} />
      </ContentContainer>
    )
  );
};

export default EditLicensor;
