import AuthForm from './LoginForm';
import AuthHeader from './LoginHeader';

const AuthPage: React.FC = () => {
  return (
    <>
      <div className='flex flex-1 flex-col py-8'>
        <AuthForm />
        <AuthHeader />
      </div>
    </>
  );
};

export default AuthPage;
