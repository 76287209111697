export interface IUserRole {
  role: string;
}

export interface IUserManagementEditData {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  position: string;
  userId: string;
  userName: string;
}

export interface IUserManagement extends IUserManagementEditData {
  isActive: boolean;
}

export interface IUser extends IUserManagement {
  fullName: string;
  permissions: Array<number>;
  role: number;
}

export interface IUserContact {
  email: string;
  firstName: string;
  fullName: string;
  lastName: string;
  phoneNumber: string;
  position: string;
}

export interface ITokenUser {
  Email: string;
  FirstName: string;
  LastName: string;
  UserId: string;
  ROLE: number;
}

export interface IRegisterDTO {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  passwordCopy: string;
  phoneNumber: string;
  position: string;
  userName: string;
}

export interface IChangePasswordValues {
  currentPassword: string;
  newPassword: string;
  newPasswordCopy: string;
}

export const UserLabels = {
  email: 'e-mail',
  firstName: 'imię',
  fullName: 'imię i nazwisko',
  lastName: 'nazwisko',
  phoneNumber: 'numer telefonu',
  position: 'stanowisko',
  userName: 'login',
};
