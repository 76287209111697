import { useState } from 'react';
import { useSelector } from 'react-redux';

import ArtistsList from './ArtistsList';
import ArtistTable from './table/ArtistTable';

import useInfiniteScroll from '../../hooks/useInfiniteScroll';
import artistsService from '../../services/artistServices';
import { RootState } from '../../store/store';
import { IArtist } from '../../types/artistTypes';
import { DataViewMode } from '../../types/globalTypes';
import FilterPanel from '../filters/FilterPanel';
import ContentContainer from '../layout/ContentContainer';
import ArtistTopbar from './ArtistTopbar';

const filters = [
  {
    name: 'Aktywny',
    values: ['Tak', 'Nie'],
  },
  {
    name: 'Sample',
    values: ['Val1', 'Val2', 'Val3'],
  },
];

const Artists: React.FC = () => {
  const viewMode = useSelector((state: RootState) => state.ui.dataViewModes.artists);
  const [queryString, setQueryString] = useState('');
  const {
    items: artists,
    loading,
    containerRef,
    lastItemRef,
  } = useInfiniteScroll<IArtist>(artistsService.getAll, queryString);

  const handleQueryChange = (value: string) => {
    setQueryString(value);
  };

  return (
    <ContentContainer title='Lista artystów' TopBar={<ArtistTopbar handleQueryChange={handleQueryChange} />}>
      <>
        <FilterPanel filters={filters} />
        {viewMode === DataViewMode.table ? (
          <ArtistTable
            artists={artists}
            containerRef={containerRef}
            lastItemRef={lastItemRef}
            isDataLoading={loading}
          />
        ) : (
          <ArtistsList
            artists={artists}
            containerRef={containerRef}
            lastItemRef={lastItemRef}
            isDataLoading={loading}
          />
        )}
      </>
    </ContentContainer>
  );
};

export default Artists;
