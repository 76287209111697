import DataTable from '../common/dataTable/DataTable';

import { IProvider } from '../../types/providerTypes';

interface IProviderTableProps {
  providers: Array<IProvider>;
  containerRef: any;
  lastItemRef: any;
  isDataLoading: boolean;
}

const ProviderTable: React.FC<IProviderTableProps> = ({ providers, containerRef, isDataLoading, lastItemRef }) => {
  const headers = ['Nazwa', 'Imię', 'Nazwisko'];

  const rows = providers.map((provider) => ({
    data: { link: `/providers/detail/${provider.id}` },
    cols: [`${provider.name}`, provider.contactFirstName, provider.contactLastName],
  }));

  return (
    <DataTable
      rows={rows}
      headers={headers}
      isDataLoading={isDataLoading}
      containerRef={containerRef}
      lastItemRef={lastItemRef}
    />
  );
};

export default ProviderTable;
