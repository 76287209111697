import { Link } from 'react-router-dom';

import { ProviderLabels, IProvider } from '../../../types/providerTypes';

import Placeholder from '../../../assets/images/placeholder.png';

interface IProviderListItemProps {
  provider: IProvider;
  innerRef?: any;
}

const ProviderListItem: React.FC<IProviderListItemProps> = ({ provider, innerRef }) => {
  return (
    <Link
      to={`/providers/detail/${provider.id}`}
      ref={innerRef}
      className='flex flex-col rounded bg-opacity-50 bg-white py-12 px-18 text-sm leading-relaxed hover:bg-opacity-90'
    >
      <div className='text-base font-medium opacity-80 p-4'>{provider.name}</div>

      <div className='flex py-4 px-12'>
        <div className='flex flex-col flex-grow '>
          <div className='tileInfo'>
            <div className='my-1'>
              <span className='tileInfo__label'>{ProviderLabels.firstName}</span>
              <span>{provider.contactFirstName}</span>
            </div>

            <div className='my-1'>
              <span className='tileInfo__label'>{ProviderLabels.lastName}</span>
              <span>{provider.contactLastName}</span>
            </div>
          </div>
        </div>

        <div className='w-28 h-28 self-end shadow-md'>
          <img src={Placeholder} alt='' />
        </div>
      </div>
    </Link>
  );
};

export default ProviderListItem;
