const commonMessages = {
  pl: {
    common: {
      add: 'dodaj',
      cancel: 'anuluj',
      choose: 'wybierz',
      confirm: 'potwierdź',
      drag: 'przeciągnij',
      drop: 'upuść',
      edit: 'edytuj',
      file: 'plik',
      here: 'tutaj',
      language: 'język',
      load: 'wczytaj',
      photo: 'zdjęcie',
      start: 'start',
      stop: 'stop',
      reset: 'reset',
      save: 'zapisz',
      settings: 'ustawienia',
      remove: 'usuń',
      upload: 'prześlij',
    },
    labels: {
      artist: 'artysta',
      artists: 'artyści',
      address: 'adres',
      authors: 'twórcy',
      basicData: 'podstawowe dane',
      certificateOfResidence: 'certyfikat',
      certificateValidFrom: 'ważny od',
      certificateValidTo: 'ważny do',
      certificateValidData: 'ważność certyfikatu',
      city: 'miasto',
      conditions: 'warunki',
      contact: 'Kontakt',
      contracts: 'kontrakty',
      country: 'kraj',
      description: 'opis',
      ean: 'ean',
      email: 'e-mail',
      firstName: 'imię',
      financialData: 'dane finansowe',
      fullName: 'imię i nazwisko',
      genre: 'rodzaj',
      label: 'label',
      lastName: 'nazwisko',
      lyrics: 'tekst utworu',
      name: 'nazwa',
      patron: 'opiekun',
      postCode: 'kod pocztowy',
      phoneNumber: 'telefon',
      role: 'rola',
      releaseDate: 'data wydania',
      registerData: 'dane rejestrowe',
      salesChannels: 'kanały sprzedaży',
      status: 'status',
      street: 'ulica',
      title: 'tytuł',
      type: 'typ',
      version: 'wersja',
      webPage: 'strona WWW',
    },
  },
  en: {
    common: {
      add: 'add',
      cancel: 'cancel',
      choose: 'choose',
      confirm: 'confirm',
      drag: 'drag',
      drop: 'drop',
      edit: 'edit',
      file: 'file',
      here: 'here',
      language: 'language',
      load: 'load',
      photo: 'photo',
      start: 'start',
      stop: 'stop',
      reset: 'reset',
      save: 'save',
      settings: 'settings',
      remove: 'remove',
      upload: 'upload',
    },
    labels: {
      artist: 'artist',
      artists: 'artists',
      address: 'address',
      authors: 'authors',
      basicData: 'basic data',
      certificateOfResidence: 'certificate',
      certificateValidFrom: 'valid from',
      certificateValidTo: 'valid to',
      certificateValidData: 'validity of certificate',
      city: 'city',
      conditions: 'conditions',
      contact: 'Contact',
      contracts: 'contracts',
      country: 'country',
      description: 'description',
      ean: 'ean',
      email: 'email',
      firstName: 'first name',
      financialData: 'financial data',
      fullName: 'full name',
      genre: 'genre',
      label: 'label',
      lastName: 'last name',
      lyrics: 'lyrics',
      name: 'name',
      patron: 'patron',
      postCode: 'post code',
      phoneNumber: 'phone number',
      registerData: 'register data',
      releaseDate: 'release date',
      role: 'role',
      salesChannels: 'sales channels',
      status: 'status',
      street: 'street',
      title: 'title',
      type: 'type',
      version: 'version',
      webPage: 'web page',
    },
  },
};

export default commonMessages;
