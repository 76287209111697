import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';

import Button from '../../common/buttons/basicButton/Button';

import { RootState } from '../../../store/store';
import { ILicensor } from '../../../types/licensorTypes';
import { showServerErrors } from '../../../utils/errorsUtils';
import { ButtonVariant } from '../../common/buttons/buttonTypes';
import licensorServices from '../../../services/licensorServices';
import LicensorStatusInfo from '../status/LicensorStatusInfo';

interface IlLcensorDetailTopbarProps {
  licensor: ILicensor;
}

const LicensorDetailTopbar: React.FC<IlLcensorDetailTopbarProps> = ({ licensor }) => {
  const { replace, push } = useHistory();
  const permissions = useSelector((state: RootState) => state.userPermissions?.Licensor);

  if (!permissions) {
    return null;
  }

  const handleDelete = async () => {
    try {
      await licensorServices.remove(licensor.id);
      toast.success('Usunięto wydawcę');
      replace('/licensors');
    } catch (errors: any) {
      showServerErrors(errors);
    }
  };

  const handleEdit = () => {
    push(`/licensors/edit/${licensor.id}`);
  };

  return (
    <div className='flex justify-between px-8 text-sm'>
      <LicensorStatusInfo status={licensor.status} />
      <div className='flex gap-4'>
        <Button
          className='flex-1 md:flex-grow-0 py-8 px-18'
          disabled={!permissions.Delete}
          onClick={handleDelete}
          variant={ButtonVariant.Remove}
        >
          Usuń
        </Button>
        <Button
          onClick={handleEdit}
          disabled={!permissions.Edit}
          variant={ButtonVariant.Submit}
          className='flex-1 md:flex-grow-0 py-8 px-18'
        >
          Edytuj
        </Button>
      </div>
    </div>
  );
};

export default LicensorDetailTopbar;
