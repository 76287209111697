import { CSSProperties } from 'react';

import { ILicensor, LicensorStatus, licensorStatusOptions } from 'types/licensorTypes';

import HoldIco from 'assets/icons/status/hold.svg';
import ProcessingIco from 'assets/icons/status/processing.svg';
import UnverifiedIco from 'assets/icons/status/unverified.svg';
import VerifiedIco from 'assets/icons/status/verified.svg';

interface IProps extends Pick<ILicensor, 'status'> {
  className?: string;
  style?: CSSProperties;
}

const icons: Record<LicensorStatus, string> = {
  [LicensorStatus.Hold]: HoldIco,
  [LicensorStatus.Revoked]: ProcessingIco,
  [LicensorStatus.Unverified]: UnverifiedIco,
  [LicensorStatus.Verified]: VerifiedIco,
  [LicensorStatus.Active]: VerifiedIco,
};

const LicensorStatusInfo = ({ status, className = '', style }: IProps) => {
  const ico = icons[status];
  const label = Object.values(licensorStatusOptions).find((opt) => opt.value === status)?.label;

  return (
    <div className={`flex flex-wrap items-center gap-x-2 ${className}`} style={style} title={label}>
      <img src={ico} alt='' /> <span className='truncate text-sm opacity-50'>{label}</span>
    </div>
  );
};

export default LicensorStatusInfo;
