import React from 'react';
import cs from 'classnames';

import Input from '../Input';
import { ITextareaProps } from '../inputTypes';

const TextArea: React.FC<ITextareaProps> = ({
  children,
  name,
  className = '',
  inputClassName = '',
  label,
  placeholder,
  style,
  wrapperClassname = '',
  value,
  ...props
}) => {
  const inputClassNames = cs('flex-1 outline-none font-regular bg-white w-full', {
    [inputClassName]: inputClassName,
  });

  return (
    <Input
      name={name}
      label={label}
      render={() => (
        <textarea {...props} className={inputClassNames} id={name} placeholder={placeholder} value={value} />
      )}
      type='textarea'
      wrapperClassName={wrapperClassname}
      value={value}
    />
  );
};

export default TextArea;
