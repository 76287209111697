import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { RootState } from '../../../store/store';

import ArtistStatusInfo from '../ArtistStatusInfo';
import Button from '../../common/buttons/basicButton/Button';

import { ButtonVariant } from '../../common/buttons/buttonTypes';
import { IArtist } from '../../../types/artistTypes';

interface IArtistDetailTopbarProps {
  artist: IArtist;
}

const ArtistDetailTopbar: React.FC<IArtistDetailTopbarProps> = ({ artist }) => {
  const { push } = useHistory();
  const permissions = useSelector((state: RootState) => state.userPermissions);

  if (!permissions) {
    return null;
  }

  const handleEdit = () => {
    push(`/artists/edit/${artist.id}`);
  };
  return (
    <div className='flex justify-between px-8 text-sm'>
      <ArtistStatusInfo className='px-8 md:px-12' artist={artist} />

      <div className='flex gap-4'>
        <Button
          onClick={handleEdit}
          disabled={!permissions.Artist.Edit.checked}
          variant={ButtonVariant.Submit}
          className='flex-1 md:flex-grow-0 py-8 px-18'
        >
          Edytuj
        </Button>
      </div>
    </div>
  );
};

export default ArtistDetailTopbar;
