import { IUser } from '../../types/userTypes';
import DataTable from '../common/dataTable/DataTable';

interface IContactsTableProps {
  containerRef: any;
  lastItemRef: any;
  contacts: Array<IUser>;
  isDataLoading: boolean;
}

const ContactsTable: React.FC<IContactsTableProps> = ({ containerRef, lastItemRef, contacts, isDataLoading }) => {
  const headers = ['Imię', 'Nazwisko', 'Email', 'Stanowisko', 'Numer telefonu'];
  const rows = contacts.map((contact) => ({
    data: {},
    cols: [contact.firstName, contact.lastName, contact.email, contact.position, contact.phoneNumber],
  }));

  return (
    <DataTable
      rows={rows}
      headers={headers}
      isDataLoading={isDataLoading}
      containerRef={containerRef}
      lastItemRef={lastItemRef}
    />
  );
};

export default ContactsTable;
