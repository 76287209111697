export enum Genre {
  'Adult Contemporary' = 0,
  'Alternative' = 1,
  'Anime' = 2,
  'Antiques & Collectibles' = 3,
  'Audio Books' = 4,
  'Blues' = 5,
  'Bossa Nova' = 6,
  'Childrens Music' = 7,
  'Chillout' = 8,
  'Christian' = 9,
  'Classical' = 10,
  'Comedy' = 11,
  'Country' = 12,
  'Dance' = 13,
  'Devotional' = 14,
  'Disco' = 15,
  'Easy Listening' = 16,
  'Educational' = 17,
  'Electronic' = 18,
  'Fitness & Workout' = 19,
  'Folk' = 20,
  'Health & Fitness' = 21,
  'Holiday Music' = 22,
  'Indian' = 23,
  'Industrial' = 24,
  'Inspirational' = 25,
  'Instructional' = 26,
  'Jazz' = 27,
  'Karaoke' = 28,
  'Latin' = 29,
  'Motown' = 30,
  'Oldies' = 31,
  'Pop' = 32,
  'R&B/Soul' = 33,
  'Rap/Hip-Hop' = 34,
  'Reggae' = 35,
  'Rock' = 36,
  'Samba' = 37,
  'Soundtracks' = 38,
  'Spoken Word' = 39,
  'Unknown' = 40,
  'Wallpaper' = 41,
  'Wallpaper Subgenres' = 42,
  'World' = 43,
}
