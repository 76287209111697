import { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';

import ContactsList from './ContactsList';
import ContactsTable from './ContactsTable';
import ContentContainer from '../layout/ContentContainer';
import ContactsTopbar from './ContactsTopbar';

import contactServices from '../../services/contactServices';
import useInfiniteScroll from '../../hooks/useInfiniteScroll';
import { DataViewMode } from '../../types/globalTypes';
import { IUser } from '../../types/userTypes';

const Contacts: React.FC = () => {
  const viewMode = useSelector((state: RootState) => state.ui.dataViewModes.contacts);
  const [queryString, setQueryString] = useState('');

  const {
    items: contacts,
    loading,
    containerRef,
    lastItemRef,
  } = useInfiniteScroll<IUser>(contactServices.getAll, queryString);

  const handleSearch = (query: string) => {
    setQueryString(query);
  };

  return (
    <ContentContainer title='Lista kontaktów' TopBar={<ContactsTopbar handleQueryChange={handleSearch} />} >
      {viewMode === DataViewMode.table ? (
        <ContactsTable
          contacts={contacts}
          containerRef={containerRef}
          lastItemRef={lastItemRef}
          isDataLoading={loading}
        />
      ) : (
        <ContactsList contacts={contacts} containerRef={containerRef} lastItemRef={lastItemRef} />
      )}

      <div>{loading && 'Loading...'} </div>
    </ContentContainer>

  );
};

export default Contacts;
