import { INavbarItem } from './navbarTypes';

import { ReactComponent as ArtistIco } from 'assets/icons/artistIco.svg';
import { ReactComponent as HomeIco } from 'assets/icons/homeIco.svg';
import { ReactComponent as ContactIco } from 'assets/icons/contactIco.svg';
import { ReactComponent as LicensorIco } from 'assets/icons/licensorIco.svg';
import { ReactComponent as FilesIco } from 'assets/icons/filesIco.svg';
import { ReactComponent as ManagmentIco } from 'assets/icons/managmentIco.svg';
import { ReactComponent as NotificationIco } from 'assets/icons/notificationIco.svg';
import { ReactComponent as ProductIco } from 'assets/icons/productIco.svg';
import { ReactComponent as SalesChannelIco } from 'assets/icons/salesChannelIco.svg';
import { ReactComponent as TrackIco } from 'assets/icons/trackIco.svg';

export const navLinks: Array<INavbarItem> = [
  {
    label: 'Home',
    icon: HomeIco,
    path: '/',
  },
  {
    label: 'Artyści',
    icon: ArtistIco,
    path: '/artists',
    // items: [
    //   {
    //     label: 'Lista artystów',
    //     icon: DistributionIco,
    //     path: '/artists',
    //   },
    // ],
  },
  {
    label: 'Utwory',
    icon: TrackIco,
    path: '/tracks',
  },
  {
    label: 'Produkty',
    icon: ProductIco,
    path: '/digitalReleases',
  },
  {
    label: 'Licencjonodawcy',
    icon: LicensorIco,
    path: '/licensors',
  },
  {
    label: 'Dostawcy',
    icon: LicensorIco,
    path: '/providers',
  },
  {
    label: 'System rozliczeń',
    icon: SalesChannelIco,
    items: [
      {
        label: 'Kanały sprzedaży',
        path: '/settlements/salesChannel',
      },
      {
        label: 'Warunki',
        path: '/settlements/conditions',
      },
    ],
  },
  {
    label: 'Powiadomienia',
    icon: NotificationIco,
    path: '/',
  },
  {
    label: 'Kontakty',
    icon: ContactIco,
    path: '/contacts',
  },
  {
    label: 'Zarządzanie',
    icon: ManagmentIco,
    path: '/users',
  },
  {
    label: 'Pliki wewnętrzne',
    icon: FilesIco,
    path: '/files',
  },
];
