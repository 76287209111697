import LicensorListItem from './LicensorListItem';

import { ILicensor } from '../../../types/licensorTypes';

interface ILicensorsListProps {
  licensors: Array<ILicensor>;
  isDataLoading: boolean;
  lastItemRef: any;
  containerRef: any;
}

const LicensorsList: React.FC<ILicensorsListProps> = ({
  licensors,
  isDataLoading,
  lastItemRef,
  containerRef,
}) => {
  return (
    <div
      style={{ gridTemplateColumns: 'repeat(auto-fit, minmax(400px, 1fr))', gap: '30px' }}
      className='flex flex-col md:grid gap-8 px-8 md:px-36 md:py-24'
      ref={containerRef}
    >
      {licensors.map((licensor, index) => {
        const isLast = index === licensors.length - 1;
        return isLast ? (
          <LicensorListItem key={licensor.id} licensor={licensor} innerRef={lastItemRef} />
        ) : (
          <LicensorListItem key={licensor.id} licensor={licensor} />
        );
      })}
      <div>{isDataLoading && 'Loading...'} </div>
    </div>
  );
};

export default LicensorsList;
