import { IUserManagement } from '../../types/userTypes';
import DataTable from '../common/dataTable/DataTable';
import UserStatusInfo from './status/UserStatusInfo';

interface IUsersTableProps {
  loading: boolean;
  containerRef: any;
  lastItemRef: any;
  users: Array<IUserManagement>;
}

const UsersTable: React.FC<IUsersTableProps> = ({ loading, containerRef, lastItemRef, users }) => {
  const headers = ['Imię', 'Nazwisko', 'Email', 'Stanowisko', 'Numer telefonu', 'Status'];

  const rows = users.map((user) => ({
    data: { link: `/users/detail/${user.userId}` },
    cols: [
      user.firstName,
      user.lastName,
      user.email,
      user.position,
      user.phoneNumber,
      <UserStatusInfo
        className='flex gap-x-2 px-8 md: -ml-4 md:pl-2/10 lg:pl-3/10 2xl:pl-4/10'
        isActive={user.isActive}
      />,
    ],
  }));

  return (
    <DataTable
      rows={rows}
      headers={headers}
      isDataLoading={loading}
      containerRef={containerRef}
      lastItemRef={lastItemRef}
    />
  );
};

export default UsersTable;
