import { conn } from '../api/BaseConnection';
import {
  ArtistNewStoredFile,
  ArtistStoredFile,
  IArtist,
  IArtistDTO,
  IArtistResponse,
  IPresspack,
} from '../types/artistTypes';
import { IListPageRequest, IListPageResponse } from '../types/globalTypes';

const endpoint = conn.endpoints.artists;

const add = (artist: IArtist | any) => {
  return conn.postJSON(`${endpoint.base}`, 'json', artist);
};

const remove = (id: string) => {
  return conn.deleteJSON(`${endpoint.base}/${id}`);
};

const edit = (artist: IArtist | any) => {
  return conn.putJSON(`${endpoint.base}`, 'json', artist);
};

const getAll = (pageInfo: IListPageRequest): Promise<IListPageResponse<IArtist>> => {
  return conn.getJSON<IListPageResponse<IArtistResponse>>(endpoint.base, 'json', pageInfo).then((res) => {
    const { items, ...data } = res;
    const formattedItems = items.map(({ photoFtpPath, ...item }) => ({ photoFile: null, ...item }));
    const response = { items: formattedItems, ...data };
    return response;
  });
};

const get = (id: string): Promise<IArtistDTO> => {
  return conn.getJSON(`${endpoint.base}/${id}`, 'json');
};

const addPressPack = (pressPack: any): Promise<any> => {
  return conn.postJSON(`${endpoint.pressPacks}`, 'json', pressPack);
};

const editPressPack = (pressPack: any): Promise<any> => {
  return conn.putJSON(`${endpoint.pressPacks}`, 'json', pressPack);
};

const getPressPack = (artistId: string): Promise<IPresspack> => {
  return conn.getJSON(`${endpoint.pressPacks}/ByArtistId/${artistId}`, 'json');
};

const addStoredFiles = (file: ArtistNewStoredFile): Promise<ArtistStoredFile> => {
  return conn.postJSON(`${endpoint.storedFiles}`, 'json', file);
};

const removeStoredFiles = (id: string): Promise<any> => {
  return conn.deleteJSON(`${endpoint.storedFiles}/${id}`, 'json');
};

const getStoredFiles = (artistId: string): Promise<ArtistStoredFile[]> => {
  return conn.getJSON(`${endpoint.storedFiles}/ByArtistId/${artistId}`, 'json');
};

const artistsService = {
  add,
  remove,
  edit,
  getAll,
  get,
  addPressPack,
  editPressPack,
  getPressPack,
  addStoredFiles,
  removeStoredFiles,
  getStoredFiles,
};

export default artistsService;
