const permissionsMessages = {
  pl: {
    changeOwnPassword: 'Zmień własne hasło',
    changePassword: 'Zmień hasło',
    changeRole: 'Zmień rolę',
    changeStatus: 'Zmiana statusu',
    create: 'Utwórz',
    createPresspack: 'Utwórz presspack',
    createStoredFile: 'Utwórz plik',
    createSubgroup: 'Utwórz podgrupę',
    createUser: 'Dodaj użytkownika',
    deactivate: 'Deaktywacja',
    delete: 'Usuwanie',
    deletePresspack: 'Usuń presspack',
    deleteStoredFile: 'Usuń plik',
    deleteSubgroup: 'Usuń podgrupę',
    deleteUser: 'Usuń użytkownika',
    detail: 'Detal',
    detailSubgroup: 'Detal podgrup',
    downgradeStatus: 'Obniżenie statusu',
    edit: 'Edycja',
    editFinancialData: 'Edycja danych finansowych',
    editLabel: 'Edycja wydawcy',
    editName: 'Edycja nazwy',
    editUser: 'Edytuj użytkownika',
    grantPermission: 'Dodaj uprawnienia',
    listGroups: 'Lista grup',
    listSubgroups: 'Widok listy podgrup',
    managePermissions: 'Zarządzaj uprawnieniami',
    merge: 'Scalanie',
    revokePermission: 'Odbierz uprawnienia',
    setUserActiveOrDeactive: 'Dezaktywuj użytkownika',
    update: 'Edycja',
    updatePresspack: 'Edytuj presspack',
    updateSubgroup: 'Edytuj podgrupę',
    view: 'Widok',
    viewContactList: 'Widok kontaktów',
    viewList: 'Widok listy',
    viewManagementList: 'Widok użytkowników',
  },
  en: {
    changeOwnPassword: 'Change own password',
    changePassword: 'Change password',
    changeRole: 'Change role',
    changeStatus: 'Change status',
    create: 'Create',
    createPresspack: 'Create presspack',
    createStoredFile: 'Create stored file',
    createSubgroup: 'Create subgroup',
    createUser: 'Create user',
    deactivate: 'Deactivate',
    delete: 'Delete',
    deletePresspack: 'Delete presspack',
    deleteStoredFile: 'Delete stored file',
    deleteSubgroup: 'Delete subgroup',
    deleteUser: 'Delete user',
    detail: 'Detail',
    detailSubgroup: 'Detail subgroup',
    downgradeStatus: 'Downgrade status',
    edit: 'Edit',
    editFinancialData: 'Edit financial data',
    editLabel: 'Edit label',
    editName: 'Edit name',
    editUser: 'Edit user',
    grantPermission: 'Grant permission',
    listGroups: 'List groups',
    listSubgroups: 'List subgroups',
    managePermissions: 'Manage permissions',
    merge: 'Merge',
    revokePermission: 'Revoke permission',
    setUserActiveOrDeactive: 'Set user active or deactive',
    update: 'Update',
    updatePresspack: 'Update presspack',
    updateSubgroup: 'Update subgroup',
    view: 'View',
    viewContactList: 'View contact list',
    viewList: 'View list',
    viewManagementList: 'View management list',
  },
};

export default permissionsMessages;
