const artistMessages = {
  pl: {
    roles: {
      mainArtist: 'artysta',
      featuredArtist: 'feat',
      composer: 'kompozytor',
      lyricist: 'słowa',
      producer: 'producent',
      orchestra: 'orkiestra',
      conductor: 'dyrygent',
      remixer: 'remixer',
      actor: 'aktor',
      arranger: 'aranżacja',
      choir: 'chór',
      soloist: 'solo',
      cymbals: 'cymbały',
      flute: 'flet',
      frenchHorn: 'róg',
      drums: 'bębny',
      davul: 'tureckie bębny',
      guitar: 'gitara',
      mandolin: 'mandolina',
      tenorSaxophone: 'saksofon tenorowy',
      accordion: 'akordeon',
      mixingEngineer: 'mixing engineer',
    },
  },
  en: {
    roles: {
      mainArtist: 'artist',
      featuredArtist: 'feat',
      composer: 'composer',
      lyricist: 'lyricist',
      producer: 'producer',
      orchestra: 'orchestra',
      conductor: 'conductor',
      remixer: 'remixer',
      actor: 'actor',
      arranger: 'arranger',
      choir: 'choir',
      soloist: 'soloist',
      cymbals: 'cymbals',
      flute: 'flute',
      frenchHorn: 'horn',
      drums: 'drums',
      davul: 'davul',
      guitar: 'guitar',
      mandolin: 'mandolin',
      tenorSaxophone: 'tenor saxophone',
      accordion: 'accordion',
      mixingEngineer: 'mixing engineer',
    },
  },
};

export default artistMessages;
