import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import ContentContainer from '../../layout/ContentContainer';
import ProviderForm from './ProviderForm';

import providersService from '../../../services/providerServices';
import { getNotEmptyFields } from '../../../utils/objectUtils';
import { showServerErrors } from '../../../utils/errorsUtils';
import { IProvider } from '../../../types/providerTypes';

const EditProvider: React.FC = () => {
  const [provider, setProvider] = useState<IProvider | null>(null);
  const { goBack } = useHistory();
  const { id } = useParams<{ id: string }>();

  const handleSubmit = async ({ id, ...provider }: IProvider) => {
    try {
      const newProvider = {
        ...getNotEmptyFields(provider),
      };
      await providersService.edit({ providerId: id, ...newProvider });
      toast.success(`Poprawnie edytowano artystę!`);
      goBack();
    } catch (errors: any) {
      showServerErrors(errors);
    }
  };

  useEffect(() => {
    providersService
      .get(id)
      .then((providerData) => {
        if (providerData) {
          setProvider(providerData);
        }
      })
      .catch((err) => {
        console.error({ err });
      });
  }, [id]);

  return (
    provider && (
      <ContentContainer title='Edycja wydawcy'>
        <ProviderForm provider={provider} onSubmit={handleSubmit} isEdit={true} />
      </ContentContainer>
    )
  );
};

export default EditProvider;
