import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ActiveFilter from './ActiveFilter';
import Button from '../common/buttons/basicButton/Button';
import DropdownPanel from '../common/panels/DropdownPanel';

import { closeFilterPanel } from '../../store/actions/ui';
import { RootState } from '../../store/store';
import { useUrlQuery } from '../../hooks/useUrlQuery';

type Filter = {
  name: string;
  values: Array<string>;
};

interface IFilterPanelProps {
  filters?: Array<Filter>;
}

const FilterPanel: React.FC<IFilterPanelProps> = ({ filters }) => {
  const [activeFilters, setActiveFilters] = useState<Array<{ name: string; value: string }>>([]);
  const filter = useSelector((state: RootState) => state.ui.filter);
  const dispatch = useDispatch();
  const { query, changeQuery } = useUrlQuery();

  const handleFilterChange = (filter: typeof query[0], checked: boolean) => {
    setActiveFilters((prev) => {
      if (checked) {
        return [...prev, filter];
      }
      return prev.filter((f) => !(f.name === filter.name && f.value === filter.value));
    });
  };

  const removeFilter = (filter: typeof query[0]) => {
    setActiveFilters((prev) => prev.filter((f) => !(f.name === filter.name && f.value === filter.value)));
  };

  const handleSubmit = () => {
    changeQuery(activeFilters);
    dispatch(closeFilterPanel());
  };

  useEffect(() => {
    setActiveFilters(query);
  }, [query]);

  return filter.showPanel ? (
    <div className='fixed right-0 top-0 z-40 h-screen w-96 bg-white-dirty shadow-lg py-12 px-18'>
      <button onClick={() => dispatch(closeFilterPanel())}>X</button>
      <div className='flex flex-wrap gap-4 mt-4 mb-6'>
        {activeFilters.map((filter) => (
          <ActiveFilter removeFilter={() => removeFilter(filter)} filter={filter} />
        ))}
      </div>
      <div className='shadow'>
        {filters?.map((filter) => (
          <DropdownPanel label={filter.name}>
            <>
              {filter.values.map((val) => (
                <>
                  <input
                    type='checkbox'
                    name={filter.name}
                    id={filter.name}
                    checked={activeFilters.some((f) => f.name === filter.name && f.value === val)}
                    onChange={(e) => {
                      const { checked } = e.target;
                      handleFilterChange({ name: filter.name, value: val }, checked);
                    }}
                  />
                  <label htmlFor={filter.name}>{val}</label>
                </>
              ))}
            </>
          </DropdownPanel>
        ))}
      </div>
      <div>
        <Button onClick={handleSubmit}>Zastosuj</Button>
      </div>
    </div>
  ) : null;
};

export default FilterPanel;
