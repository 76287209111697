import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import ContentContainer from 'components/layout/ContentContainer';
import DigitalReleaseForm from './DigitalReleaseForm';

import digitalReleasesService from 'services/digitalReleaseService';
import {
  IDigitalRelease,
  DigitalReleaseRequest,
  MusicProductType,
  MusicProductStatus,
} from 'types/digitalReleaseTypes';
import { getNotEmptyFields } from 'utils/objectUtils';
import { showServerErrors } from 'utils/errorsUtils';

const AddDigitalRelease: React.FC = () => {
  const { t } = useTranslation();
  const { goBack } = useHistory();
  const newDigitalRelease: IDigitalRelease = {
    id: '',
    title: '',
    vesion: '',
    ean: '',
    pLine: '',
    cLine: '',
    collectiveManager: '',
    releaseDate: '',
    plates: [],
    artists: [],
    labels: [],
    type: MusicProductType.album,
    genre: '',
    coverFile: '',
    status: MusicProductStatus.readyToPublish,
    processingFlag: false,
  };

  const handleSubmit = async (digitalReleaseData: DigitalReleaseRequest) => {
    try {
      const newDigitalRelease = getNotEmptyFields(digitalReleaseData);
      await digitalReleasesService.add(newDigitalRelease);
      toast.success(t('digitalRelease.toast.added'));
      goBack();
    } catch (errors: any) {
      showServerErrors(errors);
    }
  };

  return (
    <ContentContainer title={t('digitalRelease.title.add')}>
      <DigitalReleaseForm digitalRelease={newDigitalRelease} onSubmit={handleSubmit} isEdit={false} />
    </ContentContainer>
  );
};

export default AddDigitalRelease;
