import { conn } from '../api/BaseConnection';
import { IListPageRequest, IListPageResponse } from '../types/globalTypes';
import { IUserManagement, IUserManagementEditData } from '../types/userTypes';

const endpoint = conn.endpoints.users;

const get = (userId: string): Promise<IUserManagement> => {
  return conn.getJSON(`${endpoint}/${userId}`, 'json', { userId });
};

const edit = (user: IUserManagementEditData): Promise<IUserManagement> => {
  return conn.putJSON(endpoint, 'json', user);
};

const activate = (userId: string): Promise<IUserManagement> => {
  return conn.patchJSON(`${endpoint}/Activate`, 'json', { userId });
};

const deactivate = (userId: string): Promise<IUserManagement> => {
  return conn.patchJSON(`${endpoint}/Deactivate`, 'json', { userId });
};

const getAll = (pageInfo: IListPageRequest): Promise<IListPageResponse<IUserManagement>> => {
  return conn.getJSON(`${endpoint}/Management`, 'json', { ...pageInfo });
};

const usersService = {
  activate,
  deactivate,
  edit,
  getAll,
  get,
};

export default usersService;
