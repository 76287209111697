import { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';

import ContentContainer from '../layout/ContentContainer';
import FilterPanel from '../filters/FilterPanel';
import LicensorsList from './tiles/LicensorList';
import LicensorTable from './LicensorTable';
import LicensorsTopbar from './LicensorsTopbar';

import licensorsService from '../../services/licensorServices';
import useInfiniteScroll from '../../hooks/useInfiniteScroll';
import { DataViewMode } from '../../types/globalTypes';
import { ILicensor } from '../../types/licensorTypes';

const filters = [
  {
    name: 'Aktywny',
    values: ['Tak', 'Nie'],
  },
  {
    name: 'Sample',
    values: ['Val1', 'Val2', 'Val3'],
  },
];

const Licensors: React.FC = () => {
  const viewMode = useSelector((state: RootState) => state.ui.dataViewModes.licensors);
  const [queryString, setQueryString] = useState('');
  const {
    items: licensors,
    loading,
    containerRef,
    lastItemRef,
  } = useInfiniteScroll<ILicensor>(licensorsService.getAll, queryString);

  const handleQueryChange = (value: string) => {
    setQueryString(value);
  };

  return (
    <ContentContainer
      title='Lista wydawców'
      TopBar={<LicensorsTopbar handleQueryChange={handleQueryChange} />}
    >
      <FilterPanel filters={filters} />

      {viewMode === DataViewMode.table ? (
        <LicensorTable
          licensors={licensors}
          containerRef={containerRef}
          lastItemRef={lastItemRef}
          isDataLoading={loading}
        />
      ) : (
        <LicensorsList
          licensors={licensors}
          containerRef={containerRef}
          lastItemRef={lastItemRef}
          isDataLoading={loading}
        />
      )}
    </ContentContainer>
  );
};

export default Licensors;
