import { IUserContact, UserLabels } from '../../types/userTypes';
import Placeholder from '../../assets/images/placeholder.png';

interface IContactListItemProps {
  contact: IUserContact;
  innerRef?: any;
}

const ContactListItem: React.FC<IContactListItemProps> = ({ contact, innerRef }) => {
  return (
    <div ref={innerRef} className='flex flex-col rounded bg-opacity-50 bg-white py-12 px-18 text-sm leading-relaxed'>
      <div className='text-base font-medium opacity-80 p-4'>
        <span>
          {contact.firstName} {contact.lastName}
        </span>
      </div>

      <div className='flex py-4 px-12'>
        <div className='flex flex-col flex-grow '>
          <div className='tileInfo'>
            <div>
              <span className='tileInfo__label'>{UserLabels.fullName}</span>
              <span>
                {contact.firstName} {contact.lastName}
              </span>
            </div>

            <div>
              <span className='tileInfo__label'>{UserLabels.email}</span>
              <span> {contact.email} </span>
            </div>

            <div>
              <span className='tileInfo__label'>{UserLabels.phoneNumber}</span>
              <span> {contact.phoneNumber} </span>
            </div>

            <div>
              <span className='tileInfo__label'>{UserLabels.position}</span>
              <span> {contact.position} </span>
            </div>
          </div>
        </div>

        <div className='w-28 h-28 self-end shadow-md'>
          <img src={Placeholder} alt='' />
        </div>
      </div>
    </div>
  );
};

export default ContactListItem;
