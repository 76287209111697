import { useSelector } from 'react-redux';
import { RootState } from 'store/store';

import Tabs from 'components/common/tabs/Tabs';
import TabsView from 'components/common/tabs/TabsView';

import { IArtist } from 'types/artistTypes';

import PressMaterialsTab from './PressMaterials/PressMaterialsTab';
import StoredFilesTab from './StoredFilesTab';
import RelatedProductsTab from './PressMaterials/RelatedProductsTab';

const ArtistDetailTabs = () => {
  const permissions = useSelector((state: RootState) => state.userPermissions?.Artist);
  const { detail }: { detail: IArtist } = useSelector((state: RootState) => state.artist);

  if (!permissions) {
    return null;
  }

  const tabs = [
    {
      tab: {
        id: 'pressMaterials',
        label: 'Materiały prasowe',
      },
      content: <PressMaterialsTab key='pressMaterials' />,
    },
    {
      tab: {
        id: 'relatedProducts',
        label: 'Powiązane produkty',
      },
      content: <RelatedProductsTab key='relatedProducts' />,
    },
    {
      tab: {
        id: 'storedFiles',
        label: 'Teczka',
      },
      content: <StoredFilesTab key='storedFiles' />,
    },
  ];

  const filteredTabs = tabs.filter(({ tab }) => {
    const { CreatePresspack } = permissions;

    if (!CreatePresspack.checked && !detail.hasPresspack && tab.id === 'pressMaterials') return false;

    return true;
  });
  return (
    <TabsView>
      <Tabs tabs={filteredTabs.map((t) => t.tab)} />

      <div style={{ padding: '40px 4vw 0' }}>{filteredTabs.map((t) => t.content)}</div>
    </TabsView>
  );
};

export default ArtistDetailTabs;
