import i18next from 'i18next';
import * as Yup from 'yup';

import { validationMessage } from 'utils/validation';
import {
  DigitalReleaseRequest,
  IDigitalRelease,
  MusicProductStatus,
  MusicProductType,
} from 'types/digitalReleaseTypes';
import { enumToSelectOptions } from '../../../utils/baseUtils';

export const digitalReleaseValidation = Yup.object().shape({
  title: Yup.string().required(validationMessage.isRequired),
  ean: Yup.string().length(9, validationMessage.length(9)).required(validationMessage.isRequired),
  artists: Yup.array().min(1, validationMessage.minItems(1)).required(validationMessage.isRequired),
});

export const formatDataToRequest = (data: IDigitalRelease) => {
  const request: DigitalReleaseRequest = { ...data };
  return request;
};

export const getStatusOptions = () => {
  const options = enumToSelectOptions(MusicProductStatus).map(({ value, label }) => ({
    value,
    label: i18next.t(`digitalRelease.statuses.${label}`),
  }));
  return options;
};

export const getTypeOptions = () => {
  const options = enumToSelectOptions(MusicProductType).map(({ value, label }) => ({
    value,
    label: i18next.t(`digitalRelease.types.${label}`),
  }));
  return options;
};
