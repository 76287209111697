import { Link } from 'react-router-dom';

import { LicensorLabels, ILicensor } from '../../../types/licensorTypes';

import Placeholder from '../../../assets/images/placeholder.png';
import LicensorStatusInfo from '../status/LicensorStatusInfo';

interface ILicensorListItemProps {
  licensor: ILicensor;
  innerRef?: any;
}

const LicensorListItem: React.FC<ILicensorListItemProps> = ({ licensor, innerRef }) => {
  return (
    <Link
      to={`/licensors/detail/${licensor.id}`}
      ref={innerRef}
      className='flex flex-col rounded bg-opacity-50 bg-white py-12 px-18 text-sm leading-relaxed hover:bg-opacity-90'
    >
      <div className='text-base font-medium opacity-80 p-4'>{licensor.name}</div>

      <div className='flex py-4 px-12'>
        <div className='flex flex-col flex-grow '>
          <div className='tileInfo'>
            <div className='my-1'>
              <span className='tileInfo__label'>{LicensorLabels.label}</span>
              <span>{licensor.label}</span>
            </div>

            <div className='flex gap-x-4 '>
              <span className='tileInfo__label'>{LicensorLabels.patron}</span>
              <span>{licensor.patron?.fullName}</span>
            </div>
          </div>
          <div className='justify-self-end '>
            <LicensorStatusInfo status={licensor.status} />
          </div>
        </div>

        <div className='w-28 h-28 self-end shadow-md'>
          <img src={Placeholder} alt='' />
        </div>
      </div>
    </Link>
  );
};

export default LicensorListItem;
