import { ArtistPropItem } from './artistTypes';

export type IDigitalRelease = {
  id: string;
  title: string;
  vesion: string;
  ean: string;
  pLine: string;
  cLine: string;
  collectiveManager: string;
  releaseDate: string;
  plates: Plate[];
  artists: ArtistPropItem[];
  labels: DigitalReleaseLabel[];
  type: MusicProductType;
  genre: string;
  coverFile: string;
  status: MusicProductStatus;
  processingFlag: boolean;
};

export type DigitalReleaseRequest = Omit<IDigitalRelease, 'id'> & {
  //TODO: Do implement it
  id: string;
};

//TODO: Do implement it
export type DigitalReleaseListItem = Pick<IDigitalRelease, 'id'>;

export type Plate = {
  id: string;
};

type DigitalReleaseLabel = {
  labelId: string;
  conditionId: string;
  trackId: string;
  isActive: boolean;
  participaction: number;
  dateFrom: string;
  dateTo: string;
};

export enum MusicProductType {
  album,
  compilation,
  single,
  ep,
  audiobook,
}

export enum MusicProductStatus {
  project,
  readyToPublish,
  processing,
  published,
  inProcessWithdrawn,
  withdrawn,
}
