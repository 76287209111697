import { conn } from '../api/BaseConnection';
import { IListPageRequest, IListPageResponse } from '../types/globalTypes';
import { IUser } from '../types/userTypes';

const endpoint = conn.endpoints.users;

const getAll = (pageInfo: IListPageRequest): Promise<IListPageResponse<IUser>> => {
  return conn.getJSON(`${endpoint}/Contacts`, 'json', { ...pageInfo });
};

const contactServices = {
  getAll,
};

export default contactServices;
