import { useState } from 'react';
import { useSelector } from 'react-redux';

import UsersList from './UsersList';
import UsersTable from './UsersTable';

import usersService from '../../services/usersService';
import useInfiniteScroll from '../../hooks/useInfiniteScroll';
import { RootState } from '../../store/store';
import { DataViewMode } from '../../types/globalTypes';
import { IUserManagement } from '../../types/userTypes';

import ContentContainer from '../layout/ContentContainer';
import FilterPanel from '../filters/FilterPanel';
import UsersTopbar from './UsersTopbar';

const filters = [
  {
    name: 'Aktywny',
    values: ['Tak', 'Nie'],
  },
  {
    name: 'Sample',
    values: ['Val1', 'Val2', 'Val3'],
  },
];

const Users: React.FC = () => {
  const viewMode = useSelector((state: RootState) => state.ui.dataViewModes.usersManagment);

  const [queryString, setQueryString] = useState('');

  const {
    items: users,
    loading,
    containerRef,
    lastItemRef,
  } = useInfiniteScroll<IUserManagement>(usersService.getAll, queryString);

  const handleSearch = (query: string) => {
    setQueryString(query);
  };

  return (
    <ContentContainer title='Lista użytkowników' TopBar={<UsersTopbar handleQueryChange={handleSearch} />} >
      <FilterPanel filters={filters} />

      {viewMode === DataViewMode.table ? (
        <UsersTable users={users} containerRef={containerRef} lastItemRef={lastItemRef} loading={loading} />
      ) : (
        <UsersList users={users} containerRef={containerRef} lastItemRef={lastItemRef} />
      )}
    </ContentContainer>
  );
};

export default Users;
