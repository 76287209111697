import ProviderListItem from './ProviderListtem';

import { IProvider } from '../../../types/providerTypes';

interface IProvidersListProps {
  providers: Array<IProvider>;
  isDataLoading: boolean;
  lastItemRef: any;
  containerRef: any;
}

const ProvidersList: React.FC<IProvidersListProps> = ({ providers, isDataLoading, lastItemRef, containerRef }) => {
  return (
    <div
      style={{ gridTemplateColumns: 'repeat(auto-fit, minmax(400px, 1fr))', gap: '30px' }}
      className='flex flex-col md:grid gap-8 px-8 md:px-36 md:py-24'
      ref={containerRef}
    >
      {providers.map((provider, index) => {
        const isLast = index === providers.length - 1;
        return isLast ? (
          <ProviderListItem key={provider.id} provider={provider} innerRef={lastItemRef} />
        ) : (
          <ProviderListItem key={provider.id} provider={provider} />
        );
      })}
      <div>{isDataLoading && 'Loading...'} </div>
    </div>
  );
};

export default ProvidersList;
