import axios, { AxiosInstance, ResponseType } from 'axios';
import qs from 'qs';
export type IConnections = typeof connections.development;

export interface IRequestError {
  status: number;
  errors?: Array<Array<string>>;
  Errors?: Array<Array<string>>;
}

class BaseConnection {
  authentication: string;
  auth_token: string;
  base_url: string;
  endpoints: Omit<IConnections, 'core'>;
  conn: AxiosInstance;
  constructor(connections: IConnections) {
    this.authentication = '';
    this.auth_token = '';

    const { core, ...endpoints } = connections;
    this.base_url = core;
    this.endpoints = endpoints;

    this.conn = axios.create({
      timeout: 100 * 1000,
    });

    this.conn.interceptors.response.use(
      (response) => response,
      (error) => {
        const serverErrorRegex = /^5[0-9]{2}$/;
        const isServerError = !error.response || serverErrorRegex.test(error.response.status);
        if (isServerError) {
          return Promise.reject({
            response: {
              data: {
                Errors: ['Przepraszamy, wystąpił błąd przy połączeniu z serwerem. Skontakuj się z ...'],
              },
            },
          });
        }
        if (error.response.status === 403) {
          return Promise.reject({
            response: {
              data: {
                Errors: ['Brak uprawnień do wybranej opcji'],
              },
            },
          });
        }

        return Promise.reject(error);
      }
    );
  }

  setAuthToken(token: string) {
    this.authentication = token;
    if (token && token.indexOf('Bearer ') === 0) {
      this.conn.defaults.headers.common.Authorization = token;
    } else {
      delete this.conn.defaults.headers.common.Authorization;
    }
  }

  getJSON<T>(url: string, type: ResponseType = 'json', payload = {}): Promise<T> {
    return new Promise((resolve, reject) => {
      //   this.queue.push(() =>
      this.conn
        .get(this.base_url + url, {
          responseType: type,
          params: payload,
          paramsSerializer: function (params) {
            return qs.stringify(params, { indices: false });
          },
        })
        .then((response) => {
          const { statusCode, data } = response.data;
          if (statusCode === 200) {
            resolve(data);
          }
        })
        .catch((err) => {
          reject(err.response.data);
        });
      //   );
    });
  }

  postJSON(endpoint: string, type: ResponseType = 'json', payload = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      //   this.queue.push(() =>
      this.conn
        .post(this.base_url + endpoint, payload, {
          responseType: type,
        })
        .then((response) => {
          const { data } = response.data;
          resolve(data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
      //   );
    });
  }

  putJSON(url: string, type: ResponseType = 'json', payload = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      //   this.queue.push(() =>
      this.conn
        .put(this.base_url + url, payload, { responseType: type })
        .then((response) => {
          const { data } = response.data;
          resolve(data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
      //   );
    });
  }

  patchJSON(url: string, type: ResponseType = 'json', payload = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      this.conn
        .patch(this.base_url + url, payload, { responseType: type })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  }

  deleteJSON(url: string, type: ResponseType = 'json', payload = {}) {
    return new Promise((resolve, reject) => {
      //   this.queue.push(() =>
      this.conn
        .delete(this.base_url + url, { responseType: type, params: payload })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err.response.data);
        });
      //   );
    });
  }

  protected all(arr: Array<any>) {
    return axios.all(arr);
  }

  // setAuthorizationErrorHandler(errorHandler) {
  //   this.authorizationErrorHandler = errorHandler;
  // }
}

const remoteEndpoints = {
  artists: {
    base: '/artists/api/Artists',
    pressPacks: '/artists/api/Presspacks',
    storedFiles: '/artists/api/StoredFiles',
  },
  auth: '/auth/api/Auth',
  conditions: {
    base: '/conditions/api/Conditions',
    licensor: '/conditions/api/Conditions/Licensor',
    templates: '/conditions/api/Conditions/Templates',
  },
  digitalReleases: { base: '/digitalrelease/api/DigitalReleases', artists: '/digitalrelease/api/Artists' },
  licensors: '/labels/api/Licensors',
  permissions: '/auth/api/Permissions',
  profiles: '/auth/api/Profiles',
  providers: '/provider/api/providers',
  salesChannel: {
    salesChannel: '/saleschannel/api/SalesChannels',
    groups: '/saleschannel/api/Groups',
    providers: '/saleschannel/api/Providers',
    subGroups: '/saleschannel/api/Subgroups',
  },
  tracks: { base: '/track/api/Tracks', artists: '/track/api/Artists' },
  users: '/auth/api/Users',
};

const connections = {
  development: {
    core: 'https://dev.magelup.mmlab.cloud',
    ...remoteEndpoints,
  },
  production: {
    core: 'https://magelup.mmlab.cloud',
    ...remoteEndpoints,
  },
  test: {
    core: 'https://localhost',
    artists: {
      base: ':5002/api/Artists',
      pressPacks: ':5002/api/Presspacks',
      storedFiles: ':5002/api/StoredFiles',
    },
    auth: ':5001/api/Auth',
    conditions: {
      base: ':5003/api/Conditions',
      licensor: ':5003/api/Conditions/Licensor',
      templates: ':5003/api/Conditions/Templates',
    },
    digitalReleases: { base: ':50010/api/DigitalReleases', artists: ':50010/api/Artists' },
    licensors: ':50011/api/Licensors',
    permissions: ':5001/api/Permissions',
    profiles: ':5001/api/Profiles',
    providers: ':5001/api/Providers',
    salesChannel: {
      groups: ':50021/api/Groups',
      salesChannel: ':50021/api/SalesChannels',
      subGroups: ':50021/api/Subgroups',
      providers: ':50021/api/Providers',
    },
    tracks: { base: ':50011/api/Tracks', artists: ':50011/api/Artists' },
    users: ':5002/api/Users',
  },
};

export const conn = new BaseConnection(connections[process.env.NODE_ENV]);

export default BaseConnection;
