import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

import ContentContainer from 'components/layout/ContentContainer';
import DropdownPanel from 'components/common/panels/DropdownPanel';
import InfoBox from 'components/common/boxes/InfoBox/InfoBox';
import InfoBoxPlaceholder from 'components/common/boxes/InfoBox/InfoBoxPlaceholder';
import Tabs from 'components/common/tabs/Tabs';
import TabContent from 'components/common/tabs/TabContent';
import TabsButtons from 'components/common/tabs/TabsButtons';
import TabsView from 'components/common/tabs/TabsView';

import Placeholder from 'assets/images/placeholder.png';
import licensorServices from 'services/licensorServices';
import { Country } from 'utils/constants/countires';
import { ILicensor, LicensorLabels, licensorTypeOptions } from 'types/licensorTypes';

import DetailItem from './DetailItem';
import ConditionsTab from './conditions/ConditionsTab';
import LicensorDetailTopbar from './LicensorDetailTopbar';

const LicensorDetail: React.FC = () => {
  const [licensor, setLicensor] = useState<ILicensor | null>(null);
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();

  useEffect(() => {
    licensorServices
      .get(id)
      .then((licensorData) => {
        setLicensor(licensorData);
      })
      .catch((err) => {
        console.error({ err });
      });
  }, [id]);

  if (!licensor) {
    return <InfoBoxPlaceholder />;
  }
  return (
    <ContentContainer title={licensor.name} TopBar={<LicensorDetailTopbar licensor={licensor} />}>
      <div className='flex flex-col gap-1'>
        <InfoBox className='bg-white-dirty p-18'>
          <InfoBox.Image src={Placeholder} />

          <InfoBox.Items>
            <InfoBox.InfoItem label={t('labels.name')} value={licensor.name} />
            <InfoBox.InfoItem
              label={t('labels.contact')}
              value={`${licensor.firstName} ${licensor.lastName}
              `}
            />
            <InfoBox.InfoItem
              label={t('labels.patron')}
              value={`${licensor.patron?.firstName} ${licensor.patron?.lastName}`}
            />

            <InfoBox.InfoItem label={t('labels.label')} value={licensor.label} />
            <InfoBox.InfoItem label={t('labels.email')} value={licensor.email} />
            <InfoBox.InfoItem />

            <InfoBox.InfoItem />
            <InfoBox.InfoItem label={t('labels.phoneNumber')} value={licensor.phoneNumber} />
            <InfoBox.InfoItem />
          </InfoBox.Items>
        </InfoBox>

        <TabsView>
          <div className='relative mb-14 '>
            <TabsButtons className='w-full p-8 flex justify-end absolute -top-8' />
            <Tabs
              tabs={[
                { label: 'Dane rejestrowe', id: 'registerData' },
                { label: 'Warunki', id: 'warunki' },
              ]}
            />
          </div>

          <ConditionsTab licensorId={licensor.id} />

          <div className='pb-30'>
            <TabContent id='registerData'>
              <div className='flex mx-auto' style={{ gap: '2vw', padding: '0 4vw', maxWidth: '1500px' }}>
                <div className='flex flex-col gap-y-2 w-full'>
                  <DropdownPanel label='Dane rejestrowe' initialExpanded>
                    <DetailItem
                      label={LicensorLabels.type}
                      value={licensorTypeOptions.find((o) => o.value === licensor.financialData.type)?.label || ''}
                    />
                    <DetailItem label={LicensorLabels.fullName} value={`${licensor.firstName} ${licensor.lastName}`} />
                    <DetailItem label={LicensorLabels.street} value={licensor.address.street} />
                    <DetailItem label={LicensorLabels.postCode} value={licensor.address.postCode} />
                    <DetailItem label={LicensorLabels.city} value={licensor.address.city} />
                    <DetailItem label={LicensorLabels.country} value={Country[licensor.address.country]} />
                  </DropdownPanel>

                  <DropdownPanel label='Dane rozliczeniowe' initialExpanded>
                    <DetailItem
                      label={LicensorLabels.identifier[licensor.financialData.type]}
                      value={licensor.financialData.identifier}
                    />
                    <DetailItem label={LicensorLabels.taxOffice} value={licensor.financialData.taxOffice} />
                    <DetailItem
                      label={LicensorLabels.costOfObtainingIncomePercent}
                      value={licensor.financialData.costOfObtainingIncomePercent.toString()}
                    />
                    <DetailItem
                      label={LicensorLabels.taxPercent}
                      value={licensor.financialData.taxPercent.toString()}
                    />
                    <DetailItem label={LicensorLabels.accountNumber} value={licensor.financialData.accountNumber} />
                    <DetailItem
                      label={LicensorLabels.certificateValidData}
                      value={`od ${licensor.certificateValidFrom} do ${licensor.certificateValidTo}`}
                    />
                  </DropdownPanel>
                </div>

                <DropdownPanel label='Certyfikat' initialExpanded>
                  <div className='relative w-full' style={{ height: '640px' }}>
                    <iframe
                      className='w-full h-full'
                      src='https://api.ngo.pl/media/get/108219'
                      title='certificateOfResidence'
                    ></iframe>
                  </div>
                </DropdownPanel>
              </div>
            </TabContent>
          </div>
        </TabsView>
      </div>
    </ContentContainer>
  );
};

export default LicensorDetail;
