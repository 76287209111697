import { ArtistStatus, IArtist } from '../../../types/artistTypes';
import DataTable from '../../common/dataTable/DataTable';
import ArtistProfileCell from './ArtistProfileCell';
import StatusInfo from 'components/statusInfo/StatusInfo';

interface IArtistTableProps {
  artists: Array<IArtist>;
  containerRef: any;
  lastItemRef: any;
  isDataLoading: boolean;
}

const ArtistTable: React.FC<IArtistTableProps> = ({ artists, containerRef, isDataLoading, lastItemRef }) => {
  const headers = ['Nazwa', 'Imię i nazwisko', 'Profil', 'Status'];
  const rows = artists.map((artist) => ({
    data: { link: `/artists/detail/${artist.id}` },
    cols: [
      `${artist.firstName} ${artist.lastName}`,
      artist.name,
      <ArtistProfileCell artist={artist} />,
      <StatusInfo className='px-8 md:pl-1/10 lg:pl-2/10 2xl:pl-3/10' status={`${ArtistStatus[artist.status]}`} />,
    ],
  }));
  return (
    <DataTable
      rows={rows}
      headers={headers}
      isDataLoading={isDataLoading}
      containerRef={containerRef}
      lastItemRef={lastItemRef}
    />
  );
};

export default ArtistTable;
