import { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { useHistory, useParams } from 'react-router';
import { toast } from 'react-toastify';

import SubmitButton from '../../common/buttons/submitButton/SubmitButton';
import SwitchBlock from '../../common/inputs/switch/SwitchBlock';
import AssignToProfileSelect from './AssignToProfileSelect';
import PermissionSection from './PermissionSection';

import permissionService from '../../../services/permissionServices';
import { PermissionsLabels, permissoinsFromDTO } from '../../../utils/permissionsUtils';
import { ICheckboxValue } from '../../../types/globalTypes';
import {
  IPermissions,
  PermissionsDomainsShort,
  IPermissionTransformDTO,
  IPermissionsActionTypes,
} from '../../../types/permissionsTypes';
import { useDispatch } from 'react-redux';
import { changePermissions } from '../../../store/actions/permissions';

const EditPermissions: React.FC = () => {
  const [initState, setInitState] = useState<IPermissions<ICheckboxValue<number>> | null>(null);
  const [changed, setChanged] = useState<IPermissionTransformDTO>({});

  const { push } = useHistory();
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();

  const handleChange = (
    domain: keyof IPermissions,
    action: keyof IPermissionsActionTypes,
    value: ICheckboxValue<number>
  ) => {
    const key = `${domain}_${action}`;
    if (!changed[key]) {
      setChanged((prev) => ({
        ...prev,
        [key]: {
          permissionDomainName: PermissionsDomainsShort[domain],
          permissionFlagName: action,
          permissionFlagValue: value.value,
          value,
        },
      }));
    } else {
      setChanged((prev) => {
        const { [key]: deleted, ...rest } = prev;
        return rest;
      });
    }
  };

  const handleSubmit = async (values: typeof initValues, bag: any) => {
    try {
      if (values) {
        dispatch(changePermissions(changed, id));
        setChanged({});
        toast.success(`Zmieniono uprawnienia`);
      }
    } catch (errors: any) {
      toast.error('Błąd: ' + errors.join(' | '));
    }
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const permResponse = await permissionService.getUserPermissions(id);
        const permissions = permissoinsFromDTO(permResponse.permissions);
        setInitState(permissions);
      } catch (error: any) {
        toast.error(`Błąd podczas pobierania danych`);
        console.error({ error });
        push('/users');
      }
    };

    getData();
  }, [id, push]);

  if (!initState) {
    return null;
  }
  const initValues = {
    Artist: initState.Artist,
    Auth: initState.Auth,
    DigitalRelease: initState.DigitalRelease,
    Licensor: initState.Licensor,
    Provider: initState.Provider,
    SalesChannel: initState.SalesChannel,
    Tracks: initState.Tracks,
  };

  return (
    <div className='flex flex-col'>
      <AssignToProfileSelect userId={id} />
      <Formik initialValues={initValues} onSubmit={handleSubmit}>
        {({ values, isSubmitting }) => (
          <Form className='flex-1'>
            <fieldset className='flex flex-col gap-4' disabled={isSubmitting}>
              <PermissionSection label='MyMusic - Administracja'>
                {Object.keys(values.Auth).map((key) => (
                  <SwitchBlock
                    key={key}
                    label={PermissionsLabels[key]}
                    name={`Auth.${key}`}
                    value={'false'}
                    onChange={(e) => handleChange('Auth', key, e.target.value)}
                    checked={false}
                  />
                ))}
              </PermissionSection>

              <PermissionSection label='MyMusic - Artyści'>
                {Object.keys(values.Artist).map((key) => (
                  <SwitchBlock
                    checked={false}
                    key={key}
                    label={PermissionsLabels[key]}
                    name={`Artist.${key}`}
                    onChange={(e) => handleChange('Artist', key, e.target.value)}
                  />
                ))}
              </PermissionSection>

              <PermissionSection label='MyMusic - Kanały sprzedaży'>
                {Object.keys(values.SalesChannel).map((key) => (
                  <SwitchBlock
                    checked={false}
                    key={key}
                    label={PermissionsLabels[key]}
                    name={`SalesChannel.${key}`}
                    onChange={(e) => handleChange('SalesChannel', key, e.target.value)}
                  />
                ))}
              </PermissionSection>

              <PermissionSection label='MyMusic - Produkty'>
                {Object.keys(values.DigitalRelease).map((key) => (
                  <SwitchBlock
                    checked={false}
                    key={key}
                    label={PermissionsLabels[key]}
                    name={`DigitalRelease.${key}`}
                    onChange={(e) => handleChange('DigitalRelease', key, e.target.value)}
                  />
                ))}
              </PermissionSection>

              <PermissionSection label='MyMusic - Wydawcy'>
                {Object.keys(values.Licensor).map((key) => (
                  <SwitchBlock
                    checked={false}
                    key={key}
                    label={PermissionsLabels[key]}
                    name={`Licensor.${key}`}
                    onChange={(e) => handleChange('Licensor', key, e.target.value)}
                  />
                ))}
              </PermissionSection>

              <PermissionSection label='MyMusic - Dostawcy'>
                {Object.keys(values.Provider).map((key) => (
                  <SwitchBlock
                    checked={false}
                    key={key}
                    label={PermissionsLabels[key]}
                    name={`Provider.${key}`}
                    onChange={(e) => handleChange('Provider', key, e.target.value)}
                  />
                ))}
              </PermissionSection>

              <PermissionSection label='MyMusic - Tracks'>
                {Object.keys(values.Tracks).map((key) => (
                  <SwitchBlock
                    checked={false}
                    key={key}
                    label={PermissionsLabels[key]}
                    name={`Tracks.${key}`}
                    onChange={(e) => handleChange('Tracks', key, e.target.value)}
                  />
                ))}
              </PermissionSection>
            </fieldset>

            <SubmitButton className='mx-auto my-8' isSubmitting={isSubmitting}>
              Zapisz
            </SubmitButton>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EditPermissions;
