import DataTable from '../common/dataTable/DataTable';
import LicensorStatusInfo from './status/LicensorStatusInfo';

import { ILicensor } from '../../types/licensorTypes';

interface ILicensorTableProps {
  licensors: Array<ILicensor>;
  containerRef: any;
  lastItemRef: any;
  isDataLoading: boolean;
}

const LicensorTable: React.FC<ILicensorTableProps> = ({ licensors, containerRef, isDataLoading, lastItemRef }) => {
  const headers = ['Nazwa', 'Label', 'Opiekun', 'Status'];

  const rows = licensors.map((licensor) => ({
    data: { link: `/licensors/detail/${licensor.id}` },
    cols: [
      `${licensor.firstName} ${licensor.lastName}`,
      licensor.label,
      licensor.patron?.fullName,
      <LicensorStatusInfo
        status={licensor.status}
        className='flex gap-x-2 px-8 md: -ml-4 md:pl-2/10 lg:pl-3/10 2xl:pl-4/10'
      />,
    ],
  }));

  return (
    <DataTable
      rows={rows}
      headers={headers}
      isDataLoading={isDataLoading}
      containerRef={containerRef}
      lastItemRef={lastItemRef}
    />
  );
};

export default LicensorTable;
