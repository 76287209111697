import { ChangeEvent } from 'react';
import { useHistory } from 'react-router-dom';

import Button from '../common/buttons/basicButton/Button';
import FilterButton from '../common/buttons/filterButton/FilterButton';
import InputSearch from '../common/inputs/searchInput/InputSearch';
import ToggleDataViewMode from '../common/toggleDataView/ToggleDataViewMode';

import { DataViewType } from '../../types/globalTypes';
import { ButtonVariant } from '../common/buttons/buttonTypes';

interface IArtistTopbarProps {
  handleQueryChange: (value: string) => void;
}

const ArtistTopbar: React.FC<IArtistTopbarProps> = ({ handleQueryChange }) => {
  const { push } = useHistory();

  const handleAddArtist = () => {
    push('/artists/add');
  };

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    handleQueryChange(e.target.value);
  };

  return (
    <div className='flex flex-wrap items-center justify-end gap-x-6 gap-y-2 '>
      <InputSearch className='w-full md:w-36 xl:w-72' placeholder='Szukaj...' name='search' onChange={handleSearch} />
      <ToggleDataViewMode viewType={DataViewType.artists} />
      <FilterButton />
      <Button onClick={handleAddArtist} className='px-24 xl:order-2' variant={ButtonVariant.Submit}>
        Dodaj artystę
      </Button>
    </div>
  );
};

export default ArtistTopbar;
