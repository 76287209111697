import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

import ContentContainer from 'components/layout/ContentContainer';
import InfoBox from 'components/common/boxes/InfoBox/InfoBox';
import InfoBoxPlaceholder from 'components/common/boxes/InfoBox/InfoBoxPlaceholder';
import Table from 'components/common/table/Table';
import Tabs from 'components/common/tabs/Tabs';
import TabsView from 'components/common/tabs/TabsView';
import TabContent from 'components/common/tabs/TabContent';

import digitalReleaseServices from 'services/digitalReleaseService';
import { IDigitalRelease } from 'types/digitalReleaseTypes';
import Placeholder from 'assets/images/placeholder.png';

import DigitalReleaseDetailTopbar from './DigitalReleaseDetailTopbar';

const DigitalReleaseDetail: React.FC = () => {
  const [digitalRelease, setDigitalRelease] = useState<IDigitalRelease | null>(null);
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();

  useEffect(() => {
    digitalReleaseServices
      .get(id)
      .then((digitalReleaseData) => {
        setDigitalRelease(digitalReleaseData);
      })
      .catch((err) => {
        console.error({ err });
      });
  }, [id]);

  if (!digitalRelease) {
    return <InfoBoxPlaceholder />;
  }
  return (
    <ContentContainer
      title={digitalRelease.title}
      TopBar={<DigitalReleaseDetailTopbar digitalRelease={digitalRelease} />}
    >
      <div className='flex flex-col gap-1'>
        <InfoBox className='bg-white-dirty p-18'>
          <InfoBox.Image src={Placeholder} />

          <InfoBox.Items>
            <InfoBox.InfoItem label={t('labels.title')} value={digitalRelease.title} />
            <InfoBox.InfoItem />
            <InfoBox.InfoItem />

            <InfoBox.InfoItem />
            <InfoBox.InfoItem />
            <InfoBox.InfoItem />

            <InfoBox.InfoItem />
            <InfoBox.InfoItem />
            <InfoBox.InfoItem />
          </InfoBox.Items>
        </InfoBox>
      </div>

      <TabsView>
        <Tabs tabs={[{ id: 'test', label: 'Test' }]} />
        <TabContent id='test'>
          <div className='mt-14'>
            <Table
              headers={[
                { key: 'test', label: 'test', sortable: true },
                { key: 'test1', label: 'test', sortable: true },
                { key: 'test2', label: 'test', sortable: true },
              ]}
              rows={[
                {
                  cols: [
                    { key: 'test', content: 'TestData', title: 'Test' },
                    { key: 'test1', content: 'TestData', title: 'Test' },
                    { key: 'test2', content: 'TestData', title: 'Test' },
                  ],
                },
              ]}
            />
          </div>
        </TabContent>
      </TabsView>
    </ContentContainer>
  );
};

export default DigitalReleaseDetail;
