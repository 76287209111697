import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import ArtistForm from './ArtistForm';
import ContentContainer from '../../layout/ContentContainer';

import artistsService from '../../../services/artistServices';
import { getNotEmptyFields } from '../../../utils/objectUtils';
import {
  addArtistStatuses,
  ArtistStatus,
  artistStatusLabels,
  IArtist,
  IArtistRequest,
} from '../../../types/artistTypes';
import { ISelectOption } from '../../common/inputs/inputTypes';
import { showServerErrors } from '../../../utils/errorsUtils';

const AddArtist: React.FC = () => {
  const { goBack } = useHistory();
  const newArtist: IArtist = {
    email: '',
    iTunesId: '',
    spotifyId: '',
    isni: '',
    firstName: '',
    id: '',
    isBand: { checked: false },
    lastName: '',
    name: '',
    phoneNumber: '',
    photoFile: null,
    status: ArtistStatus.Unverified,
    // isAssignedToAnyActiveItem: false,
  };

  const statusOptions: Array<ISelectOption> = addArtistStatuses.map((key) => artistStatusLabels[key]);

  const handleSubmit = async (artistData: IArtistRequest) => {
    try {
      const newArtist = getNotEmptyFields(artistData);
      await artistsService.add(newArtist);
      toast.success(`Dodano nowego artystę!`);
      goBack();
    } catch (errors: any) {
      showServerErrors(errors);
    }
  };

  return (
    <ContentContainer title='Dodaj nowego artystę'>
      <ArtistForm artist={newArtist} statusOptions={statusOptions} onSubmit={handleSubmit} isEdit={false} />
    </ContentContainer>
  );
};

export default AddArtist;
