import { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';

import ContentContainer from '../layout/ContentContainer';
import FilterPanel from '../filters/FilterPanel';
import ProvidersList from './tiles/ProviderList';
import ProviderTable from './ProviderTable';
import ProviderTopbar from './ProviderTopbar';

import providersService from '../../services/providerServices';
import useInfiniteScroll from '../../hooks/useInfiniteScroll';
import { DataViewMode } from '../../types/globalTypes';
import { IProvider } from '../../types/providerTypes';

const filters = [
  {
    name: 'Aktywny',
    values: ['Tak', 'Nie'],
  },
  {
    name: 'Sample',
    values: ['Val1', 'Val2', 'Val3'],
  },
];

const Providers: React.FC = () => {
  const viewMode = useSelector((state: RootState) => state.ui.dataViewModes.providers);
  const [queryString, setQueryString] = useState('');
  const {
    items: providers,
    loading,
    containerRef,
    lastItemRef,
  } = useInfiniteScroll<IProvider>(providersService.getAll, queryString);

  const handleQueryChange = (value: string) => {
    setQueryString(value);
  };

  return (
    <ContentContainer title='Lista dostawców' TopBar={<ProviderTopbar handleQueryChange={handleQueryChange} />}>
      <FilterPanel filters={filters} />

      {viewMode === DataViewMode.table ? (
        <ProviderTable
          providers={providers}
          containerRef={containerRef}
          lastItemRef={lastItemRef}
          isDataLoading={loading}
        />
      ) : (
        <ProvidersList
          providers={providers}
          containerRef={containerRef}
          lastItemRef={lastItemRef}
          isDataLoading={loading}
        />
      )}
    </ContentContainer>
  );
};

export default Providers;
