import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import ContentContainer from '../../layout/ContentContainer';
import ProviderForm from './ProviderForm';

import providersService from '../../../services/providerServices';
import { getNotEmptyFields } from '../../../utils/objectUtils';
import { IProvider, ProviderType } from '../../../types/providerTypes';
import { showServerErrors } from '../../../utils/errorsUtils';
import { Country } from 'utils/constants/countires';

const AddProvider: React.FC = () => {
  const { goBack } = useHistory();
  const newProvider: IProvider = {
    contactFirstName: '',
    contactLastName: '',
    email: '',
    id: '',
    identifier: '',
    name: '',
    phoneNumber: '',
    type: ProviderType.Company,
    address: {
      city: '',
      country: Country.Poland,
      postCode: '',
      street: '',
    },
  };

  const handleSubmit = async (providerData: IProvider) => {
    try {
      const newProvider = getNotEmptyFields(providerData);

      await providersService.add(newProvider);
      toast.success(`Dodano nowego dostawcę!`);
      goBack();
    } catch (errors: any) {
      showServerErrors(errors);
    }
  };

  return (
    <ContentContainer title='Dodaj dostawcę'>
      <ProviderForm provider={newProvider} onSubmit={handleSubmit} isEdit={false} />
    </ContentContainer>
  );
};

export default AddProvider;
