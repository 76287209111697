import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';

import Button from '../../common/buttons/basicButton/Button';

import { RootState } from '../../../store/store';
import { IUserManagement } from '../../../types/userTypes';
import { showServerErrors } from '../../../utils/errorsUtils';
import { ButtonVariant } from '../../common/buttons/buttonTypes';
import usersService from '../../../services/usersService';
import UserStatusInfo from '../status/UserStatusInfo';

interface IUserDetailTopbarProps {
  user: IUserManagement;
}

const UserDetailTopbar: React.FC<IUserDetailTopbarProps> = ({ user }) => {
  const { push } = useHistory();
  const permissions = useSelector((state: RootState) => state.userPermissions);

  if (!permissions) {
    return null;
  }

  const handleDeactivate = async () => {
    try {
      await usersService.deactivate(user.userId);
      toast.success('Dezaktywowano użytkownika');
      push('/users');
    } catch (errors: any) {
      showServerErrors(errors);
    }
  };

  const handleEdit = () => {
    push(`/users/edit/${user.userId}`);
  };

  return (
    <div className='flex justify-between px-8 text-sm'>
      <UserStatusInfo isActive={user.isActive} />
      <div className='flex gap-4'>
        <Button
          className='flex-1 md:flex-grow-0 py-8 px-18'
          disabled={!permissions.Auth.SetUserActiveOrDeactive.checked}
          onClick={handleDeactivate}
          variant={ButtonVariant.Remove}
        >
          Usuń
        </Button>
        <Button
          onClick={handleEdit}
          disabled={!permissions.Auth.EditUser.checked}
          variant={ButtonVariant.Submit}
          className='flex-1 md:flex-grow-0 py-8 px-18'
        >
          Edytuj
        </Button>
      </div>
    </div>
  );
};

export default UserDetailTopbar;
