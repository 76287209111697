import Switch from './Switch';
import { IInputProps } from '../inputTypes';

export interface ISwitchBlockProps extends IInputProps {
  label: string;
  name: string;
}

const SwitchBlock: React.FC<ISwitchBlockProps> = ({ label, name, className = '', ...props }) => {
  return (
    <div className={`flex gap-4 items-center justify-between ${className}`}>
      <span>{label}</span>
      <Switch name={name} {...props} />
    </div>
  );
};

export default SwitchBlock;
