import { useField } from 'formik';
import { IInputProps } from '../inputTypes';
import Datepicker from './DatepickerInput';

const DatepickerField: React.FC<IInputProps> = ({ name, ...props }) => {
  const [field] = useField(name);

  return <Datepicker {...props} {...field} />;
};

export default DatepickerField;
