import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import ArtistForm from './ArtistForm';
import ContentContainer from 'components/layout/ContentContainer';

import artistsService from 'services/artistServices';
import { artistFromDTO } from 'utils/artistUtils';
import { getNotEmptyFields } from 'utils/objectUtils';
import { showServerErrors } from 'utils/errorsUtils';
import { ISelectOption } from 'components/common/inputs/inputTypes';
import { artistStatusLabels, editArtistStatuses, IArtist, IArtistRequest } from 'types/artistTypes';

const EditArtist: React.FC = () => {
  const [artist, setArtist] = useState<IArtist | null>(null);
  const { goBack } = useHistory();
  const { id } = useParams<{ id: string }>();

  const statusOptions: Array<ISelectOption> = editArtistStatuses.map((key) => artistStatusLabels[key]);

  const handleSubmit = async ({ id, ...artist }: IArtistRequest) => {
    try {
      const newArtist = {
        ...getNotEmptyFields(artist),
        artistId: id,
      };
      await artistsService.edit(newArtist);
      toast.success(`Poprawnie edytowano artystę!`);
      goBack();
    } catch (errors: any) {
      showServerErrors(errors);
    }
  };

  useEffect(() => {
    artistsService
      .get(id)
      .then((artistData) => {
        if (artistData) {
          const formattedArtist = artistFromDTO(artistData);
          setArtist(formattedArtist);
        }
      })
      .catch((err) => {
        console.error({ err });
      });
  }, [id]);

  return (
    artist && (
      <ContentContainer title='Edycja artysty'>
        <ArtistForm artist={artist} onSubmit={handleSubmit} statusOptions={statusOptions} isEdit={true} />
      </ContentContainer>
    )
  );
};

export default EditArtist;
