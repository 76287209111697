import { Form, Formik } from 'formik';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import { RootState } from 'store/store';

import DeleteButton from 'components/common/buttons/deleteButton/DeleteButton';
import FormSection from 'components/common/forms/FormSection';
import MaskedField from 'components/common/inputs/maskedInput/MaskedField';
import SelectInput from 'components/common/inputs/select/Select';
import SubmitButton from 'components/common/buttons/submitButton/SubmitButton';
import TextField from 'components/common/inputs/textInput/TextField';

import { IProvider, ProviderLabels, providerTypeOptions } from 'types/providerTypes';

import providersService from 'services/providerServices';
import { showServerErrors } from 'utils/errorsUtils';
import { Mask } from 'utils/constants/constants';
import { enumToSelectOptions } from 'utils/baseUtils';
import { Country } from 'utils/constants/countires';

interface IEditProviderProps {
  provider: IProvider;
  isEdit: boolean;
  onSubmit: (values: IProvider) => void;
}

const ProviderForm: React.FC<IEditProviderProps> = ({ provider, isEdit, onSubmit }) => {
  const permissions = useSelector((state: RootState) => state.userPermissions);
  const { replace } = useHistory();

  if (!permissions) {
    return null;
  }

  const countryOptions = enumToSelectOptions(Country);

  const handleSubmit = async (values: typeof provider) => {
    await onSubmit(values);
  };

  const handleDelete = async () => {
    try {
      await providersService.remove(provider.id);
      toast.success('Usunięto wydawcę');
      replace('/providers');
    } catch (errors: any) {
      showServerErrors(errors);
    }
  };

  return (
    <Formik
      initialValues={provider}
      // validationSchema={providerValidations}
      onSubmit={handleSubmit}
      validateOnMount
    >
      {({ errors, isSubmitting, values, setFieldValue }) => (
        <Form className='flex flex-col gap-x-6 mx-auto  px-24 lg:px-36 pb-36 xl:gap-x-10 bg-white-dirty bg-opacity-70 border-t border-gray border-opacity-40 pt-30'>
          <FormSection isDisabled={isSubmitting} label='Dane podstawowe'>
            <TextField name='name' label={ProviderLabels.name} placeholder={errors.name} />
          </FormSection>

          <FormSection isDisabled={isSubmitting} sublabel='Reprezentant'>
            <TextField name='contactFirstName' label={ProviderLabels.firstName} placeholder={errors.contactFirstName} />
            <TextField name='contactLastName' label={ProviderLabels.lastName} placeholder={errors.contactLastName} />
            <TextField name='email' label={ProviderLabels.email} placeholder={errors.email} />
            <MaskedField
              mask={Mask.Phone}
              name='phoneNumber'
              label={ProviderLabels.phoneNumber}
              placeholder={errors.phoneNumber}
            />
          </FormSection>

          <FormSection isDisabled={isSubmitting} sublabel='Adres'>
            <TextField name='address.street' label={ProviderLabels.street} placeholder={errors.address?.street} />
            <TextField name='address.postCode' label={ProviderLabels.postCode} placeholder={errors.address?.postCode} />
            <TextField name='address.city' label={ProviderLabels.city} placeholder={errors.address?.city} />
            <SelectInput
              defaultValue={values.address?.country}
              isSearchable
              items={countryOptions}
              label={ProviderLabels.country}
              name='address.country'
              placeholder={errors.address?.country}
              onChange={({ value }) => {
                setFieldValue('address.country', value);
              }}
            />
          </FormSection>

          <FormSection isDisabled={isSubmitting} label='Dane rejestrowe'>
            <SelectInput
              name='type'
              label={ProviderLabels.type}
              items={providerTypeOptions}
              defaultValue={values.type}
              placeholder={errors.type}
              onChange={({ value }) => {
                setFieldValue('type', value);
              }}
              showErrors={false}
            />
            <TextField
              name='identifier'
              label={ProviderLabels.identifier[values.type]}
              placeholder={errors.identifier}
            />
          </FormSection>

          <div className='flex gap-x-2'>
            <SubmitButton isSubmitting={isSubmitting} className='mt-6 '>
              Zapisz
            </SubmitButton>

            {isEdit && (
              <DeleteButton isSubmitting={isSubmitting} disabled={!permissions.Provider.Delete} onClick={handleDelete}>
                Usuń
              </DeleteButton>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ProviderForm;
