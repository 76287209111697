import React, { useEffect, useState } from 'react';
import licensorsService from '../../../services/licensorServices';
import { LicensorLabels } from '../../../types/licensorTypes';
import { ISelectOption } from '../../common/inputs/inputTypes';
import SelectInput from '../../common/inputs/select/Select';

interface Props {
  defaultValue: string;
}

const PatronSelect: React.FC<Props> = ({ defaultValue }) => {
  const [patronOptions, setPatronOptions] = useState<Array<ISelectOption>>([
    { value: null, label: '' },
  ]);

  useEffect(() => {
    const getPatrons = async () => {
      try {
        const patrons = await licensorsService.getPartons();
        const options = patrons.map((patron) => ({ label: patron.userName, value: patron.id }));

        setPatronOptions(options);
      } catch (err) {
        console.error(err);
      }
    };

    getPatrons();
  }, []);

  return (
    <SelectInput
      name='patron'
      label={LicensorLabels.patron}
      items={patronOptions}
      defaultValue={defaultValue}
    />
  );
};

export default PatronSelect;
