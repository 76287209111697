import { IDigitalRelease } from 'types/digitalReleaseTypes';

import DigitalReleaseListItem from './DigitalReleaseListItem';

interface IDigitalReleasesListProps {
  digitalReleases: Array<IDigitalRelease>;
  isDataLoading: boolean;
  lastItemRef: any;
  containerRef: any;
}

const DigitalReleasesList: React.FC<IDigitalReleasesListProps> = ({ digitalReleases, isDataLoading, lastItemRef, containerRef }) => {
  return (
    <div
      style={{ gridTemplateColumns: 'repeat(auto-fit, minmax(400px, 1fr))', gap: '30px' }}
      className='flex flex-col md:grid gap-8 px-8 md:px-36 md:py-24'
      ref={containerRef}
    >
      {digitalReleases.map((digitalRelease, index) => {
        const isLast = index === digitalReleases.length - 1;
        return isLast ? (
          <DigitalReleaseListItem key={digitalRelease.id} digitalRelease={digitalRelease} innerRef={lastItemRef} />
        ) : (
          <DigitalReleaseListItem key={digitalRelease.id} digitalRelease={digitalRelease} />
        );
      })}
      <div>{isDataLoading && 'Loading...'} </div>
    </div>
  );
};

export default DigitalReleasesList;
