import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { toast } from 'react-toastify';

import ContentContainer from '../layout/ContentContainer';
import FormSection from '../common/forms/FormSection';
import MaskedField from '../common/inputs/maskedInput/MaskedField';
import SubmitButton from '../common/buttons/submitButton/SubmitButton';
import TextField from '../common/inputs/textInput/TextField';

import usersService from '../../services/usersService';
import { IUserManagement } from '../../types/userTypes';

import { Mask } from '../../utils/constants/constants';
import { showServerErrors } from '../../utils/errorsUtils';

const EditUser: React.FC = () => {
  const [user, setUser] = useState<IUserManagement | null>(null);

  const { id } = useParams<{ id: string }>();
  const { goBack } = useHistory();

  useEffect(() => {
    usersService
      .get(id)
      .then((user) => {
        setUser(user);
      })
      .catch((err) => {
        console.error({ err });
      });
  }, [id]);

  if (!user) {
    return null;
  }

  const initValues = {
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    phoneNumber: user.phoneNumber,
    position: user.position,
    userName: user.userName,
  };

  const handleSubmit = async (values: typeof initValues) => {
    try {
      const editedUser = {
        userId: user.userId,
        ...values,
      };

      await usersService.edit(editedUser);
      toast.success(`Użytkownik zaktualizowany`);
      goBack();
    } catch (errors: any) {
      showServerErrors(errors);
    }
  };

  return (
    <ContentContainer title='Edycja użytkownika'>
      <Formik initialValues={initValues} onSubmit={handleSubmit}>
        {({ values, isSubmitting }) => (
          <Form className='flex flex-col gap-x-6 mx-auto  px-24 lg:px-36 pb-36 xl:gap-x-10 bg-white-dirty bg-opacity-70 border-t border-gray border-opacity-40 pt-30'>
            <FormSection isDisabled={isSubmitting} label='Podstawowe'>
              <TextField name='firstName' label='Imię' />
              <TextField name='lastName' label='Nazwisko' />
              <TextField name='email' label='Adres e-mail' />
            </FormSection>

            <FormSection isDisabled={isSubmitting} label='Logowanie'>
              <TextField name='userName' label='Login' placeholder='Wprowadź login' type='text' />
              <TextField name='password' label='Hasło' type='password' />
              <TextField name='passwordCopy' label='Potwierdź hasło' type='password' />
            </FormSection>

            <FormSection isDisabled={isSubmitting} label='Dodatkowe'>
              <MaskedField mask={Mask.Phone} name='phoneNumber' label='Numer telefonu' />
              <TextField name='position' label='Stanowisko' type='text' />
            </FormSection>

            <SubmitButton isSubmitting={isSubmitting} type='submit' className='w-80'>
              Zapisz
            </SubmitButton>
          </Form>
        )}
      </Formik>
    </ContentContainer>
  );
};

export default EditUser;
