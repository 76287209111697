import * as Yup from 'yup';
import { Regex } from '../../utils/constants/constants';
import { validationMessage } from '../../utils/validation';
import { Values } from './LoginForm';

export const loginInitValues: Values = {
  login: '',
  password: '',
};

export const passwordRegEx = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*_+=-?])(?=^.{8,100}$)/;

export const loginValidationSchema = Yup.object().shape({
  login: Yup.string().max(100, validationMessage.max(100)).required(validationMessage.isRequired),
  password: Yup.string()
    .max(100, validationMessage.max(100))
    .matches(passwordRegEx, validationMessage.weekPassword)
    .required(validationMessage.isRequired),
});

export const registerInitValues = {
  email: '',
  firstName: '',
  lastName: '',
  password: '',
  passwordCopy: '',
  phoneNumber: '',
  position: '',
  userName: '',
  profile: 0,
};

export const registerValidationSchema = Yup.object().shape({
  email: Yup.string().email(validationMessage.invalidEmail).required(validationMessage.isRequired),
  firstName: Yup.string().required(validationMessage.isRequired),
  lastName: Yup.string().required(validationMessage.isRequired),
  password: Yup.string().matches(passwordRegEx, validationMessage.weekPassword).required(validationMessage.isRequired),
  passwordCopy: Yup.string()
    .oneOf([Yup.ref('password'), null], validationMessage.passwordsDontMatch)
    .required(validationMessage.isRequired),
  phoneNumber: Yup.string().max(15, validationMessage.max(15)).matches(Regex.phone, validationMessage.invalidPhone),
  position: Yup.string().required(validationMessage.isRequired),
  userName: Yup.string().required(validationMessage.isRequired),
});

export const forgotPasswordInitValues = {
  email: '',
};

export const forgotPasswordValidation = Yup.object().shape({
  email: Yup.string().email(validationMessage.invalidEmail).required(validationMessage.isRequired),
});
