import TileViewLinkItem from 'components/common/tileView/TileViewLinkItem';

import { ArtistLabels, IArtist } from 'types/artistTypes';
import { ReactComponent as SpotifyIco } from 'assets/icons/lists/spotify.svg';
import { ReactComponent as ITunesIco } from 'assets/icons/lists/itunes.svg';

import ArtistStatusInfo from './ArtistStatusInfo';

interface IArtistListItemProps {
  artist: IArtist;
  innerRef?: any;
}

const ArtistListItem: React.FC<IArtistListItemProps> = ({ artist, innerRef }) => {
  return (
    <TileViewLinkItem
      title={artist.name}
      img=''
      link={`/artists/detail/${artist.id}`}
      content={
        <>
          <div className='tileInfo'>
            <div className='my-1'>
              <span className='tileInfo__label'>{ArtistLabels.fullName}</span>
              <span>
                {artist.firstName} {artist.lastName}
              </span>
            </div>

            <div className='flex gap-x-4 '>
              <span className='tileInfo__label'>{'Profil'}</span>
              <span className='flex gap-x-4 w-min'>
                <span>
                  <SpotifyIco />
                </span>
                <span>
                  <ITunesIco />
                </span>
                <span>ISNI</span>
              </span>
            </div>
          </div>
          <ArtistStatusInfo className='justify-self-end' artist={artist} />
        </>
      }
    />
  );
};

export default ArtistListItem;
