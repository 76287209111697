import { Form, Formik } from 'formik';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';

import DeleteButton from 'components/common/buttons/deleteButton/DeleteButton';
import MaskedField from 'components/common/inputs/maskedInput/MaskedField';
import FormSection from 'components/common/forms/FormSection';
import ImageField from 'components/common/inputs/imageInput/ImageField';
import SelectInput from 'components/common/inputs/select/Select';
import SubmitButton from 'components/common/buttons/submitButton/SubmitButton';
import SwitchBlock from 'components/common/inputs/switch/SwitchBlock';
import TextField from 'components/common/inputs/textInput/TextField';

import { RootState } from 'store/store';
import { IArtist, IArtistRequest } from 'types/artistTypes';
import { ISelectOption } from 'components/common/inputs/inputTypes';

import artistsService from 'services/artistServices';
import { Mask } from 'utils/constants/constants';
import { showServerErrors } from 'utils/errorsUtils';
import {
  artistToDTO,
  artistValidations,
  ckeckNameEditable,
  filterStatusOptions,
  getArtistInitValues,
} from 'utils/artistUtils';

interface IEditArtistProps {
  artist: IArtist;
  statusOptions: Array<ISelectOption>;
  isEdit: boolean;
  onSubmit: (values: IArtistRequest) => void;
}

const ArtistForm: React.FC<IEditArtistProps> = ({ artist, isEdit, statusOptions: initStatusOptions, onSubmit }) => {
  const permissions = useSelector((state: RootState) => state.userPermissions);
  const { replace } = useHistory();

  const initValues = getArtistInitValues(artist);

  if (!permissions) {
    return null;
  }

  const statusOptions = isEdit ? filterStatusOptions(initStatusOptions, artist, permissions) : initStatusOptions;
  const nameEditIdEnabled = !isEdit || ckeckNameEditable(artist, permissions);

  const handleSubmit = async (values: IArtist) => {
    const artistDTO = artistToDTO(values);
    await onSubmit(artistDTO);
  };

  const handleDelete = async () => {
    try {
      await artistsService.remove(artist.id);
      toast.success('Usunięto wydawcę');
      replace('/providers');
    } catch (errors: any) {
      showServerErrors(errors);
    }
  };

  return (
    <Formik initialValues={initValues} validationSchema={artistValidations} onSubmit={handleSubmit} validateOnMount>
      {({ errors, isSubmitting, values, setFieldValue }) => (
        <Form className='flex flex-col gap-x-6 mx-auto  px-24 lg:px-36 pb-36 xl:gap-x-10 bg-white-dirty bg-opacity-70 border-t border-gray border-opacity-40 pt-30'>
          <FormSection isDisabled={isSubmitting} label='Foto'>
            <ImageField name='photoFile' className='mx-auto md:mx-0 mb-8' imgSrc={values.photoFile?.path} />
          </FormSection>
          <FormSection isDisabled={isSubmitting} label='Dane podstawowe'>
            <TextField isBlocked={!nameEditIdEnabled} name='name' label='Nazwa' helperText={errors.name} />
            <SwitchBlock name='isBand' label='Czy zespół' className='mb-4 text-sm gap-x-2' />
          </FormSection>

          <FormSection isDisabled={isSubmitting} sublabel='Reprezentant'>
            <TextField name='firstName' label='Imię' helperText={errors.firstName} />
            <TextField name='lastName' label='Nazwisko' helperText={errors.lastName} />
            <MaskedField name='phoneNumber' label='Numer telefonu' mask={Mask.Phone} helperText={errors.phoneNumber} />
            <TextField name='email' label='E-mail' helperText={errors.email} />
          </FormSection>

          <FormSection isDisabled={isSubmitting} label='Profile'>
            <TextField name='isni' label='ISNI' helperText={errors.isni} />
            <TextField name='spotifyId' label='Sptify ID' helperText={errors.spotifyId} />
            <TextField name='iTunesId' label='Itunes ID' helperText={errors.iTunesId} />
          </FormSection>

          <FormSection isDisabled={isSubmitting} label='Status'>
            <SelectInput name='status' label='Typ' items={statusOptions} defaultValue={artist.status} />
          </FormSection>

          <div className='flex gap-x-2'>
            <SubmitButton isSubmitting={isSubmitting} className='mt-6 '>
              Zapisz
            </SubmitButton>

            {isEdit && (
              <DeleteButton isSubmitting={isSubmitting} disabled={!permissions.Artist.Delete} onClick={handleDelete}>
                Usuń
              </DeleteButton>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ArtistForm;
