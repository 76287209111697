import { ISelectOption } from 'components/common/inputs/inputTypes';
import SelectInput from 'components/common/inputs/select/Select';
import ConditionForm from 'components/conditions/form/ConditionForm';
import ContentContainer from 'components/layout/ContentContainer';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { toast } from 'react-toastify';

import conditionsService from 'services/conditionsServices';
import { ConditionTemplate, ICondition } from 'types/conditionTypes';
import { showServerErrors } from 'utils/errorsUtils';

const AddLicensorCondition = () => {
  const [templates, setTemplates] = useState<Array<ISelectOption>>([]);
  const [template, setTemplate] = useState<ConditionTemplate | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { licensorId } = useParams<{ licensorId: string }>();
  const { goBack } = useHistory();

  const fetchTemplates = async () => {
    const res = await conditionsService.getAll();
    const options = res.map(({ id, name }) => ({ label: name, value: id }));
    setTemplates(options);
  };

  const fetchTemplate = async (id: string) => {
    setIsLoading(true);
    const res = await conditionsService.get(id);
    setTemplate(res);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchTemplates();
  }, []);

  const handleSubmit = async (values: ICondition) => {
    try {
      await conditionsService.add({ ...values, licensorId: licensorId || values.licensorId, isTemplate: !licensorId });
      toast.success(`Dodano warunek dla wydawcy!`);
      goBack();
    } catch (errors: any) {
      showServerErrors(errors);
    }
  };

  return (
    <ContentContainer title='Dodaj szablon'>
      <div>
        <div className='bg-white-dirty bg-opacity-70 p-12 md:p-36'>
          <SelectInput
            items={templates}
            name='templateSelect'
            label='Szablon'
            showErrors={false}
            onChange={({ value }) => fetchTemplate(value)}
            defaultValue={templates[0]?.value}
          />
        </div>
        {isLoading ? (
          <div>Loader placeholder...</div>
        ) : (
          template && <ConditionForm template={template} onSubmit={handleSubmit} />
        )}
      </div>
    </ContentContainer>
  );
};

export default AddLicensorCondition;
