import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { IDigitalRelease } from 'types/digitalReleaseTypes';

import Placeholder from 'assets/images/placeholder.png';

interface IDigitalReleaseListItemProps {
  digitalRelease: IDigitalRelease;
  innerRef?: any;
}

const DigitalReleaseListItem: React.FC<IDigitalReleaseListItemProps> = ({ digitalRelease, innerRef }) => {
  const { t } = useTranslation();
  return (
    <Link
      to={`/DigitalReleases/detail/${digitalRelease.id}`}
      ref={innerRef}
      className='flex flex-col rounded bg-opacity-50 bg-white py-12 px-18 text-sm leading-relaxed hover:bg-opacity-90'
    >
      <div className='text-base font-medium opacity-80 p-4'>{digitalRelease.title}</div>

      <div className='flex py-4 px-12'>
        <div className='flex flex-col flex-grow '>
          <div className='tileInfo'>
            <div className='my-1'>
              <span className='tileInfo__label'>{t('labels.title')}</span>
              <span>{digitalRelease.title}</span>
            </div>

            <div className='my-1'>
              {/* <span className='tileInfo__label'>{DigitalReleaseLabels.lastName}</span>
              <span>{DigitalRelease.contactLastName}</span> */}
            </div>
          </div>
        </div>

        <div className='w-28 h-28 self-end shadow-md'>
          <img src={Placeholder} alt='' />
        </div>
      </div>
    </Link>
  );
};

export default DigitalReleaseListItem;
