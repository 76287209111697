import React, { useEffect, useState } from 'react';
import providerServices from '../../../services/providerServices';

import { IProvider, ProviderType } from '../../../types/providerTypes';
import InfoBox from '../../common/boxes/InfoBox/InfoBox';
import ContentContainer from '../../layout/ContentContainer';
import { useParams } from 'react-router';
import InfoBoxPlaceholder from '../../common/boxes/InfoBox/InfoBoxPlaceholder';

import Placeholder from '../../../assets/images/placeholder.png';
import ProviderDetailTopbar from './ProviderDetailTopbar';
import { Country } from 'utils/constants/countires';
import Tabs from 'components/common/tabs/Tabs';
import TabsView from 'components/common/tabs/TabsView';
import TabContent from 'components/common/tabs/TabContent';
import DetailItem from 'components/licensors/detail/DetailItem';
import DropdownPanel from 'components/common/panels/DropdownPanel';
import TabsButtons from 'components/common/tabs/TabsButtons';
import { useTranslation } from 'react-i18next';
import SalesChannelsTab from './tabs/SalesChannelsTab';

const ProviderDetail: React.FC = () => {
  const [provider, setProvider] = useState<IProvider | null>(null);
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();

  useEffect(() => {
    providerServices
      .get(id)
      .then((providerData) => {
        setProvider(providerData);
      })
      .catch((err) => {
        console.error({ err });
      });
  }, [id]);

  if (!provider) {
    return <InfoBoxPlaceholder />;
  }
  return (
    <ContentContainer title={provider.name} TopBar={<ProviderDetailTopbar provider={provider} />}>
      <div className='flex flex-col gap-1'>
        <InfoBox className='bg-white-dirty p-18'>
          <InfoBox.Image src={Placeholder} />

          <InfoBox.Items>
            <InfoBox.InfoItem label={t('labels.name')} value={provider.name} />
            <InfoBox.InfoItem
              label={t('labels.contact')}
              value={`${provider.contactFirstName} ${provider.contactLastName}
              `}
            />
            <InfoBox.InfoItem />

            <InfoBox.InfoItem />
            <InfoBox.InfoItem label={t('labels.email')} value={provider.email} />
            <InfoBox.InfoItem />

            <InfoBox.InfoItem />
            <InfoBox.InfoItem label={t('labels.phoneNumber')} value={provider.phoneNumber} />
            <InfoBox.InfoItem />
          </InfoBox.Items>
        </InfoBox>
      </div>

      <TabsView>
        <div className='relative mb-14 '>
          <TabsButtons className='w-full p-8 flex justify-end absolute -top-8' />
          <Tabs
            tabs={[
              { label: t('labels.registerData'), id: 'registerData' },
              { label: t('labels.salesChannels'), id: 'salesChannels' },
            ]}
          />
        </div>

        <SalesChannelsTab />

        <div className='pb-30'>
          <TabContent id='registerData'>
            <div className='flex mx-auto' style={{ gap: '2vw', padding: '0 4vw', maxWidth: '1500px' }}>
              <div className='flex flex-col gap-y-2 w-full'>
                <DropdownPanel label='Dane rejestrowe' initialExpanded>
                  <DetailItem label={t('labels.type')} value={t(`provider.type.${ProviderType[provider.type]}`)} />
                  <DetailItem label={t('labels.name')} value={provider.name} />
                  <DetailItem label={t('labels.street')} value={provider.address.street} />
                  <DetailItem label={t('labels.postCode')} value={provider.address.postCode} />
                  <DetailItem label={t('labels.city')} value={provider.address.city} />
                  <DetailItem label={t('labels.country')} value={Country[provider.address.country]} />
                </DropdownPanel>

                <DropdownPanel label='Dane rozliczeniowe' initialExpanded>
                  <DetailItem
                    label={t(`provider.identifier.${ProviderType[provider.type]}`)}
                    value={provider.identifier}
                  />
                </DropdownPanel>
              </div>

              <DropdownPanel label='Certyfikat' initialExpanded>
                <div className='relative w-full' style={{ height: '640px' }}>
                  <iframe
                    className='w-full h-full'
                    src='https://api.ngo.pl/media/get/108219'
                    title='certificateOfResidence'
                  ></iframe>
                </div>
              </DropdownPanel>
            </div>
          </TabContent>
        </div>
      </TabsView>
    </ContentContainer>
  );
};

export default ProviderDetail;
