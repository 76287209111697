import React from 'react';

interface Props {
  label: string;
  value: string;
}

const DetailItem: React.FC<Props> = ({ label, value }) => {
  return (
    <div className='flex justify-between py-12 px-18 text-sm bg-white bg-opacity-10 mb-1'>
      <span className='text-black text-opacity-40 capitalize-first'>{label}</span>
      <span className='text-black text-opacity-80 capitalize-first'>{value}</span>
    </div>
  );
};

export default DetailItem;
