
interface IActiveFilterProps {
  filter: { name: string; value: string };
  removeFilter: () => void;
}

const ActiveFilter: React.FC<IActiveFilterProps> = ({ filter, removeFilter }) => {
  return (
    <div className='bg-white p-8 shadow'>
      <span>{filter.value}</span>
      <span>
        <button onClick={removeFilter}>X</button>
      </span>
    </div>
  );
};

export default ActiveFilter;
