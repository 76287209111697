const providerMessages = {
  pl: {
    identifier: {
      label: 'identyfikator',
      Private: 'PESEL',
      Company: 'NIP',
    },
    type: {
      Private: 'osoba prywatna',
      Company: 'firma',
    },
  },
  en: {
    identifier: {
      label: 'identifier',
      Private: 'PESEL',
      Company: 'NIP',
    },
    type: {
      Private: 'private person',
      Company: 'company',
    },
  },
};

export default providerMessages;
