import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';

import AddArtist from 'components/artists/form/AddArtist';
import AddLicensor from 'components/licensors/form/AddLicensor';
import Artists from 'components/artists/Artists';
import ArtistDetail from 'components/artists/detail/ArtistDetail';
import AuthPage from 'components/auth/LoginPage';
import ChangePassword from 'components/profile/ChangePassword';

import Conditions from 'components/conditions/Conditions';
import ConditionDetail from 'components/conditions/detail/ConditionDetail';
import AddLicensorCondition from 'components/licensors/detail/conditions/AddLicensorCondition';
import AddCondition from 'components/conditions/form/AddCondition';
import EditCondition from 'components/conditions/form/EditCondition';

import Contacts from 'components/contacts/Contacts';
import ContentContainer from 'components/layout/ContentContainer';

import DigitalReleases from 'components/digitalRelease/DigitalRelease';
import DigitalReleaseDetail from 'components/digitalRelease/detail/DigitalReleaseDetail';
import EditDigitalRelease from 'components/digitalRelease/form/EditDigitalRelease';
import AddDigitalRelease from 'components/digitalRelease/form/AddDigitalRelease';

import EditArtist from 'components/artists/form/EditArtist';
import EditLicensor from 'components/licensors/form/EditLicensor';
import EditUser from 'components/users/EditUser';

import Home from 'components/home/Home';
import Layout from 'components/layout/Layout';

import Licensors from 'components/licensors/Licensors';
import LicensorDetail from 'components/licensors/detail/LicensorDetail';

import Profile from 'components/profile/Profile';

import Providers from 'components/providers/Providers';
import AddProvider from 'components/providers/form/AddProvider';
import EditProvider from 'components/providers/form/EditProvider';
import ProviderDetail from 'components/providers/detail/ProviderDetail';

import RegisterForm from 'components/auth/Register/RegisterForm';
import RemindPassword from 'components/auth/remindPassword/RemindPassword';

import SalesChannel from 'components/salesChannel/SalesChannel';
import AddSalesChannel from 'components/salesChannel/form/AddSalesChannel';
import EditSalesChannel from 'components/salesChannel/form/EditSalesChannel';

import Tracks from 'components/tracks/Tracks';
import TrackDetail from 'components/tracks/detail/TrackDetail';
import AddTrack from 'components/tracks/form/AddTrack';
import EditTrack from 'components/tracks/form/EditTrack';

import Users from 'components/users/Users';
import UserDetail from 'components/users/detail/UserDetail';

import PrivateRoute from './PrivateRoute';

import { authorize } from 'store/actions/auth';
import { RootState } from 'store/store';

const AppRouter: React.FC = () => {
  const { isAuth } = useSelector((state: RootState) => state.auth);
  const location = useLocation<{ from: string }>();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(authorize());
  }, [dispatch, location]);

  return (
    <Layout>
      <Switch>
        <Route exact path='/login'>
          {isAuth ? <Redirect to={location.state?.from || '/'} /> : <AuthPage />}
        </Route>

        <Route exact path='/remindPassword'>
          <RemindPassword />
        </Route>

        <PrivateRoute path='/'>
          <Route exact path='/artists/add'>
            <AddArtist />
          </Route>
          <Route exact path='/artists/edit/:id'>
            <EditArtist />
          </Route>
          <Route exact path='/artists/detail/:id'>
            <ArtistDetail />
          </Route>
          <Route exact path='/artists'>
            <Artists />
          </Route>

          <Route exact path='/settlements/conditions/add'>
            <AddCondition />
          </Route>
          <Route exact path='/settlements/conditions/add/:licensorId'>
            <AddLicensorCondition />
          </Route>
          <Route exact path='/settlements/conditions/edit/:id'>
            <EditCondition />
          </Route>
          <Route exact path='/settlements/conditions/detail/:id'>
            <ConditionDetail />
          </Route>
          <Route exact path='/settlements/conditions'>
            <Conditions />
          </Route>

          <Route exact path='/contacts'>
            <Contacts />
          </Route>

          <Route exact path='/digitalReleases/add'>
            <AddDigitalRelease />
          </Route>
          <Route exact path='/digitalReleases/edit/:id'>
            <EditDigitalRelease />
          </Route>
          <Route exact path='/digitalReleases/detail/:id'>
            <DigitalReleaseDetail />
          </Route>
          <Route exact path='/digitalReleases'>
            <DigitalReleases />
          </Route>

          <Route exact path='/licensors/add'>
            <AddLicensor />
          </Route>
          <Route exact path='/licensors/edit/:id'>
            <EditLicensor />
          </Route>
          <Route exact path='/licensors/detail/:id'>
            <LicensorDetail />
          </Route>
          <Route exact path='/licensors'>
            <Licensors />
          </Route>

          <Route exact path='/providers/add'>
            <AddProvider />
          </Route>
          <Route exact path='/providers/edit/:id'>
            <EditProvider />
          </Route>
          <Route exact path='/providers/detail/:id'>
            <ProviderDetail />
          </Route>
          <Route exact path='/providers'>
            <Providers />
          </Route>

          <Route exact path='/settlements/salesChannel/add/:id?'>
            <AddSalesChannel />
          </Route>
          <Route exact path='/settlements/salesChannel/edit/:id'>
            <EditSalesChannel />
          </Route>
          <Route exact path='/settlements/salesChannel'>
            <SalesChannel />
          </Route>

          <Route exact path='/tracks/add'>
            <AddTrack />
          </Route>
          <Route exact path='/tracks/edit/:id'>
            <EditTrack />
          </Route>
          <Route exact path='/tracks/detail/:id'>
            <TrackDetail />
          </Route>
          <Route exact path='/tracks'>
            <Tracks />
          </Route>

          <Route exact path='/users/add'>
            <RegisterForm />
          </Route>
          <Route exact path='/users/edit/:id'>
            <EditUser />
          </Route>
          <Route exact path='/users/detail/:id'>
            <UserDetail />
          </Route>
          <Route exact path='/users'>
            <Users />
          </Route>

          <Route path='/profile'>
            <Profile />
            <Switch>
              <Route exact path='/profile/changePassword'>
                <ContentContainer title='Zmień hasło'>
                  <ChangePassword />
                </ContentContainer>
              </Route>
            </Switch>
          </Route>

          <Route exact path='/'>
            <Home />
          </Route>
        </PrivateRoute>
      </Switch>
    </Layout>
  );
};

export default AppRouter;
