import { IUserManagement, UserLabels } from '../../types/userTypes';

import UserStatusInfo from './status/UserStatusInfo';
import TileViewLinkItem from 'components/common/tileView/TileViewLinkItem';

interface IUserListItemProps {
  user: IUserManagement;
  innerRef?: any;
}

const UserListItem: React.FC<IUserListItemProps> = ({ user, innerRef }) => {
  return (
    <div ref={innerRef}>
      <TileViewLinkItem
        title={`${user.firstName} ${user.lastName}`}
        link={`/users/detail/${user.userId}`}
        img=''
        content={
          <>
            <div className='tileInfo'>
              <div>
                <span className='tileInfo__label'>{UserLabels.fullName}</span>
                <span>
                  {user.firstName} {user.lastName}
                </span>
              </div>

              <div>
                <span className='tileInfo__label'>{UserLabels.email}</span>
                <span> {user.email} </span>
              </div>

              <div>
                <span className='tileInfo__label'>{UserLabels.phoneNumber}</span>
                <span> {user.phoneNumber} </span>
              </div>

              <div>
                <span className='tileInfo__label'>{UserLabels.position}</span>
                <span> {user.position} </span>
              </div>
            </div>
            <div className='justify-self-end '>
              <UserStatusInfo isActive={user.isActive} />
            </div>
          </>
        }
      />
    </div>
  );
};

export default UserListItem;
