import { Form, Formik } from 'formik';
import { toast } from 'react-toastify';

import ContentContainer from '../../layout/ContentContainer';
import MaskedField from '../../common/inputs/maskedInput/MaskedField';
import SelectInput from '../../common/inputs/select/Select';
import SubmitButton from '../../common/buttons/submitButton/SubmitButton';
import TextField from '../../common/inputs/textInput/TextField';

import { IRegisterDTO } from '../../../types/userTypes';
import { useProfiles } from '../../../hooks/useProfiles';
import { registerInitValues, registerValidationSchema } from '../authHelpers';
import { Mask } from '../../../utils/constants/constants';
import FormSection from '../../common/forms/FormSection';
import authService from '../../../services/authServices';
import { getNotEmptyFields } from '../../../utils/objectUtils';
import { showServerErrors } from '../../../utils/errorsUtils';

const RegisterForm: React.FC = () => {
  const { selectOptions } = useProfiles();

  const handleSubmit = async (values: IRegisterDTO) => {
    try {
      await authService.register(getNotEmptyFields(values));
      toast.success('Dodano nowego użytkownika');
    } catch (errors: any) {
      showServerErrors(errors);
    }
  };

  return (
    <ContentContainer title='Dodaj nowego uytkownika'>
      <Formik initialValues={registerInitValues} onSubmit={handleSubmit} validationSchema={registerValidationSchema}>
        {({ values, isSubmitting }) => (
          <Form className='flex flex-col gap-x-6 mx-auto  px-24 lg:px-36 pb-36 xl:gap-x-10 bg-white-dirty bg-opacity-70 border-t border-gray border-opacity-40 pt-30'>
            <FormSection isDisabled={isSubmitting} label='Profil'>
              <SelectInput name='profile' items={selectOptions} label='Profil' defaultValue={values.profile} />
            </FormSection>
            <FormSection isDisabled={isSubmitting} label='Podstawowe'>
              <TextField name='firstName' label='Imię' />
              <TextField name='lastName' label='Nazwisko' />
              <TextField name='email' label='Adres e-mail' />
            </FormSection>

            <FormSection isDisabled={isSubmitting} label='Logowanie'>
              <TextField name='userName' label='Login' placeholder='Wprowadź login' type='text' />
              <TextField name='password' label='Hasło' type='password' />
              <TextField name='passwordCopy' label='Potwierdź hasło' type='password' />
            </FormSection>

            <FormSection isDisabled={isSubmitting} label='Dodatkowe'>
              <MaskedField mask={Mask.Phone} name='phoneNumber' label='Numer telefonu' />
              <TextField name='position' label='Stanowisko' type='text' />
            </FormSection>

            <SubmitButton isSubmitting={isSubmitting} type='submit' className='w-80'>
              Zapisz
            </SubmitButton>
          </Form>
        )}
      </Formik>
    </ContentContainer>
  );
};

export default RegisterForm;
