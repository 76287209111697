import React, { useEffect, useState } from 'react';
import usersService from '../../../services/usersService';

import { IUserManagement } from '../../../types/userTypes';
import InfoBox from '../../common/boxes/InfoBox/InfoBox';
import ContentContainer from '../../layout/ContentContainer';
import DropdownPanel from '../../common/panels/DropdownPanel';
import { useParams } from 'react-router';
import InfoBoxPlaceholder from '../../common/boxes/InfoBox/InfoBoxPlaceholder';

import Placeholder from '../../../assets/images/placeholder.png';
import UserDetailTopbar from './UserDetailTopbar';
import EditPermissions from '../permissions/EditPermissions';

const UserDetail: React.FC = () => {
  const [user, setUser] = useState<IUserManagement | null>(null);
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    usersService
      .get(id)
      .then((userData) => {
        setUser(userData);
      })
      .catch((err) => {
        console.error({ err });
      });
  }, [id]);

  if (!user) {
    return <InfoBoxPlaceholder />;
  }
  return (
    <ContentContainer title={`${user.firstName} ${user.lastName}`} TopBar={<UserDetailTopbar user={user} />}>
      <div className='flex flex-col gap-1'>
        <InfoBox className='bg-white-dirty p-18'>
          <InfoBox.Image src={Placeholder} />

          <InfoBox.Items>
            <InfoBox.InfoItem label={'Imię i nazwisko'} value={`${user.firstName} ${user.lastName}`} />
            <InfoBox.InfoItem label={'Numer telefonu'} value={user.phoneNumber} />
            <InfoBox.InfoItem label={'Stanowisko'} value={user.position} />
            <InfoBox.InfoItem label={'Login'} value={user.userName} />
            <InfoBox.InfoItem label={'Email'} value={user.email} />
            <InfoBox.InfoItem label={'Status'} value={user.isActive ? 'Aktywny' : 'Nieaktywny'} />
            <InfoBox.InfoItem /> <InfoBox.InfoItem /> <InfoBox.InfoItem />
          </InfoBox.Items>
        </InfoBox>
        <DropdownPanel label='Uprawnienia' initialExpanded>
          <EditPermissions />
        </DropdownPanel>
      </div>
    </ContentContainer>
  );
};

export default UserDetail;
