import React, { CSSProperties } from 'react';

import HoldIco from 'assets/icons/status/hold.svg';
import ProcessingIco from 'assets/icons/status/processing.svg';
import UnverifiedIco from 'assets/icons/status/unverified.svg';
import VerifiedIco from 'assets/icons/status/verified.svg';

interface Props {
  status: string;
  className?: string;
  style?: CSSProperties;
}

const icons: Record<string, string> = {
  Active: VerifiedIco,
  Draft: UnverifiedIco,
  Hold: HoldIco,
  Processing: UnverifiedIco,
  Revoked: ProcessingIco,
  Unverified: UnverifiedIco,
  Withdrawn: ProcessingIco,
  Verified: VerifiedIco,
  true: VerifiedIco,
  false: HoldIco,
};

const labels: Record<keyof typeof icons, string> = {
  Active: 'Aktywny',
  Draft: 'Draft/Projekt',
  Hold: 'Zawieszony',
  Processing: 'Przetwarzanie',
  Revoked: 'Wycofany',
  Unverified: 'Niezweryfikowany',
  Withdrawn: 'Wycofany',
  Verified: 'Zweryfikowany',
  true: 'Aktywny',
  false: 'Nieaktywny',
};

const StatusInfo = ({ className = '', style, status }: Props) => {
  const icon = icons[status];
  const label = labels[status];
  return (
    <div className={`flex  items-center gap-x-2 whitespace-nowrap ${className}`} style={style} title={label}>
      <img src={icon} alt='' /> <span className='truncate text-sm opacity-50'>{label}</span>
    </div>
  );
};

export default StatusInfo;
