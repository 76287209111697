import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';

//import i18n from './_i18n/i18n'
// import { useTranslation } from 'react-i18next';
import AppRouter from './routing/AppRouter';
import store from './store/store';

import 'react-toastify/dist/ReactToastify.css';

// curl -i -H "Accept:application/json" -H "Content-Type:application/json" -H "Authorization: Bearer ACCESS-TOKEN" -XPOST "https://gorest.co.in/public-api/users" -d '{"name":"Tenali Ramakrishna", "gender":"Male", "email":"tenali.ramakrishna@15ce.com", "status":"Active"}'

function App() {
  // const { t } = useTranslation();

  return (
    <Provider store={store}>
      <Router>
        <AppRouter />
      </Router>
      <ToastContainer />
    </Provider>
  );
}

export default App;
