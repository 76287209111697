import { useTranslation } from 'react-i18next';

import DataTable from 'components/common/dataTable/DataTable';
import StatusInfo from 'components/statusInfo/StatusInfo';

import { IDigitalRelease, MusicProductStatus } from 'types/digitalReleaseTypes';

interface IDigitalReleaseTableProps {
  digitalReleases: Array<IDigitalRelease>;
  containerRef: any;
  lastItemRef: any;
  isDataLoading: boolean;
}

const DigitalReleaseTable: React.FC<IDigitalReleaseTableProps> = ({
  digitalReleases,
  containerRef,
  isDataLoading,
  lastItemRef,
}) => {
  const { t } = useTranslation();
  const headers = [
    <div className='capitalize-first'>{t('labels.title')}</div>,
    <div className='capitalize-first'>{t('labels.artists')}</div>,
    'EAN',
    <div className='capitalize-first'>{t('labels.status')}</div>,
  ];

  const rows = digitalReleases.map((dr) => ({
    data: { link: `/digitalReleases/detail/${dr.id}` },
    cols: [
      dr.title,
      <div>
        {dr.artists.map((artist) => (
          <div>{artist.name}</div>
        ))}
      </div>,
      dr.ean,
      <StatusInfo status={`${MusicProductStatus[dr.status]}`} />,
    ],
  }));

  return (
    <DataTable
      rows={rows}
      headers={headers}
      isDataLoading={isDataLoading}
      containerRef={containerRef}
      lastItemRef={lastItemRef}
    />
  );
};

export default DigitalReleaseTable;
