import Button from 'components/common/buttons/basicButton/Button';
import Table from 'components/common/table/Table';
import { TableRowType } from 'components/common/table/tableTypes';
import TabContent from 'components/common/tabs/TabContent';
import StatusInfo from 'components/statusInfo/StatusInfo';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import conditionsService from 'services/conditionsServices';
import { ConditionTemplate } from 'types/conditionTypes';

interface Props {
  licensorId: string;
}

const ConditionsTab = ({ licensorId }: Props) => {
  const [conditions, setConditions] = useState<Array<ConditionTemplate>>([]);

  const { push } = useHistory();

  const fetchConditions = useCallback(async () => {
    const res = await conditionsService.getForLicensor(licensorId);
    setConditions(res);
  }, [licensorId]);

  useEffect(() => {
    fetchConditions();
  }, [fetchConditions]);

  const rows: TableRowType[] = conditions.map((condition) => ({
    onClick: () => {
      push(`/settlements/conditions/detail/${condition.id}`);
    },
    cols: [
      { key: 'name', title: condition.name, content: condition.name },
      {
        key: 'products',
        title: condition.conditionsGroups.length.toString(),
        content: condition.conditionsGroups.length,
      },
      {
        key: 'status',
        title: `${condition.isActive}`,
        content: (
          <StatusInfo className='flex gap-x-2 px-8 md: -ml-4 md:pl-3/10 lg:pl-4/10 ' status={`${condition.isActive}`} />
        ),
      },
    ],
  }));

  return (
    <TabContent id='warunki'>
      <Button
        onClick={() => {
          push(`/settlements/conditions/add/${licensorId}`);
        }}
        className='mb-4 ml-auto mr-4'
      >
        Dodaj nowy
      </Button>
      <Table
        headers={[
          { label: 'Nazwa', key: 'name', sortable: true },
          { label: 'Liczba produktów', key: 'products' },
          { label: 'Status', key: 'status' },
        ]}
        rows={rows}
      />
    </TabContent>
  );
};

export default ConditionsTab;
