import jwtDecode from 'jwt-decode';
import { conn } from '../api/BaseConnection';

import { IChangePasswordValues, ITokenUser, IUser } from '../types/userTypes';

const getUserFromToken = (): IUser | null => {
  const token = localStorage.getItem('token');
  if (token) {
    const data: ITokenUser = jwtDecode(token);

    const user: IUser = {
      firstName: data.FirstName,
      lastName: data.LastName,
      fullName: `${data.FirstName} ${data.LastName}`,
      userId: data.UserId,
      email: data.Email,
      role: data.ROLE,
      userName: data.Email,

      isActive: true,
      permissions: [],
      phoneNumber: '123',
      position: 'asd',
    };

    return user;
  }

  return null;
};

const getCurrentUser = (): Promise<IUser> => {
  return new Promise((resolve, reject) => {
    const userData = getUserFromToken();
    if (userData) {
      resolve(userData);
    }

    reject({ error: ['User not found'] });
  });
};

const changePassword = (userId: string, passwords: IChangePasswordValues): Promise<any> => {
  return conn.postJSON('/api/Auth/ChangePassword', 'json', { userId, ...passwords });
};

const currentUser = {
  changePassword,
  getCurrentUser,
};

export default currentUser;
