import { Form, Formik } from 'formik';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';

import Datepicker from '../../common/inputs/Datepicker/DatepickerField';
import ImageField from '../../common/inputs/imageInput/ImageField';
import FileField from '../../common/inputs/FileInput/FileField';
import FormSection from '../../common/forms/FormSection';
import MaskedField from '../../common/inputs/maskedInput/MaskedField';
import SelectInput from '../../common/inputs/select/Select';
import SubmitButton from '../../common/buttons/submitButton/SubmitButton';
import TextField from '../../common/inputs/textInput/TextField';

import {
  ILicensor,
  LicensorLabels,
  LicensorType,
  licensorTypeOptions,
  TEMP_ILicensorDTO,
} from '../../../types/licensorTypes';
import { ISelectOption } from '../../common/inputs/inputTypes';

import {
  licensorValidations,
  ckeckNameEditable,
  filterStatusOptions,
  getLicensorInitValues,
} from '../../../utils/licensorUtils';
import { Mask } from '../../../utils/constants/constants';
import PatronSelect from './PatronSelect';
import { enumToSelectOptions } from 'utils/baseUtils';
import { Country } from 'utils/constants/countires';

interface IEditLicensorProps {
  licensor: ILicensor;
  statusOptions: Array<ISelectOption>;
  isEdit: boolean;
  onSubmit: (values: TEMP_ILicensorDTO) => void;
}

const LicensorForm: React.FC<IEditLicensorProps> = ({
  licensor,
  isEdit,
  statusOptions: initStatusOptions,
  onSubmit,
}) => {
  const permissions = useSelector((state: RootState) => state.userPermissions);
  const initValues = getLicensorInitValues(licensor);

  if (!permissions) {
    return null;
  }

  const countryOptions = enumToSelectOptions(Country);
  const statusOptions = isEdit ? filterStatusOptions(initStatusOptions, licensor, permissions) : initStatusOptions;
  const nameEditIdEnabled = !isEdit || ckeckNameEditable(licensor, permissions);

  const handleSubmit = async (values: typeof initValues) => {
    const { certificateOfResidence, logoFile, patron, address, id, contracts, ...restOfLicensor } = values;

    const data: TEMP_ILicensorDTO = {
      base64CertificateOfResidenceFile: certificateOfResidence?.base64String,
      base64LogoFile: logoFile?.base64String || '',
      patronId: patron?.userId || '',
      removePhoto: !logoFile?.base64String,
      adress: address,
      licensorId: id,
      ...restOfLicensor,
    };
    await onSubmit(data);
  };

  return (
    <Formik initialValues={initValues} validationSchema={licensorValidations} onSubmit={handleSubmit} validateOnMount>
      {({ errors, isSubmitting, values, setFieldValue }) => (
        <Form className='flex flex-col gap-x-6 mx-auto  px-24 lg:px-36 pb-36 xl:gap-x-10 bg-white-dirty bg-opacity-70 border-t border-gray border-opacity-40 pt-30'>
          <FormSection isDisabled={isSubmitting} label='Logo'>
            <ImageField name='logoFile' className='mx-auto md:mx-0 mb-8' imgSrc={values.logoFile?.path} />
          </FormSection>

          <FormSection isDisabled={isSubmitting} label='Dane podstawowe'>
            <TextField
              isBlocked={!nameEditIdEnabled}
              name='name'
              label={LicensorLabels.name}
              placeholder={errors.name}
            />
            <TextField name='label' label={LicensorLabels.label} placeholder={errors.label} />
          </FormSection>

          <FormSection isDisabled={isSubmitting} sublabel='Reprezentant'>
            <TextField name='firstName' label={LicensorLabels.firstName} placeholder={errors.firstName} />
            <TextField name='lastName' label={LicensorLabels.lastName} placeholder={errors.lastName} />
            <TextField name='email' label={LicensorLabels.email} placeholder={errors.email} />
            <MaskedField
              mask={Mask.Phone}
              name='phoneNumber'
              label={LicensorLabels.phoneNumber}
              placeholder={errors.phoneNumber}
            />
          </FormSection>

          <FormSection isDisabled={isSubmitting} label='Dane rejestrowe'>
            <SelectInput
              name='financialData.type'
              label={LicensorLabels.type}
              items={licensorTypeOptions}
              placeholder={errors.financialData?.type}
              showErrors={false}
            />

            <TextField
              name='financialData.representiveInstanceName'
              label={
                values.financialData.type === LicensorType.Company
                  ? LicensorLabels.representiveInstanceName
                  : 'Imię i nazwisko'
              }
              placeholder={errors.financialData?.representiveInstanceName}
            />
          </FormSection>

          <FormSection isDisabled={isSubmitting} sublabel='Adres'>
            <TextField name='address.street' label={LicensorLabels.street} placeholder={errors.address?.street} />
            <TextField name='address.postCode' label={LicensorLabels.postCode} placeholder={errors.address?.postCode} />
            <TextField name='address.city' label={LicensorLabels.city} placeholder={errors.address?.city} />
            <SelectInput
              defaultValue={values.address?.country}
              isSearchable
              items={countryOptions}
              label={LicensorLabels.country}
              name='address.country'
              placeholder={errors.address?.country}
            />
          </FormSection>

          <FormSection isDisabled={isSubmitting} sublabel='Dane rozliczeniowe'>
            <TextField
              name='financialData.identifier'
              label={LicensorLabels.identifier[values.financialData.type]}
              placeholder={errors.financialData?.identifier}
            />
            {values.financialData.type === LicensorType.Private && (
              <>
                <TextField
                  name='financialData.taxOffice'
                  label={LicensorLabels.taxOffice}
                  placeholder={errors.financialData?.taxOffice}
                />
                <TextField
                  name='financialData.taxPercent'
                  label={LicensorLabels.taxPercent}
                  placeholder={errors.financialData?.taxPercent}
                />
                <TextField
                  name='financialData.costOfObtainingIncomePercent'
                  label={LicensorLabels.costOfObtainingIncomePercent}
                  placeholder={errors.financialData?.costOfObtainingIncomePercent}
                />
              </>
            )}
          </FormSection>
          <FormSection isDisabled={isSubmitting}>
            <TextField
              name='financialData.accountNumber'
              label={LicensorLabels.accountNumber}
              placeholder={errors.financialData?.accountNumber}
            />
          </FormSection>
          <FormSection isDisabled={isSubmitting}>
            <FileField
              name='certificateOfResidence'
              label={LicensorLabels.certificateOfResidence}
              placeholder={'prześlij plik PDF'}
            />
            <Datepicker
              name='certificateValidFrom'
              label={LicensorLabels.certificateValidFrom}
              placeholder={errors.certificateValidFrom}
            />
            <Datepicker
              name='certificateValidTo'
              label={LicensorLabels.certificateValidTo}
              placeholder={errors.certificateValidTo}
            />
          </FormSection>

          <FormSection isDisabled={isSubmitting} label='Opiekun'>
            <PatronSelect defaultValue={values.patron?.userId || ''} />
          </FormSection>

          <FormSection isDisabled={isSubmitting} label='Status'>
            <SelectInput
              name='status'
              label={LicensorLabels.status}
              items={statusOptions}
              defaultValue={licensor.status}
            />
          </FormSection>

          <SubmitButton isSubmitting={isSubmitting} className='mt-6 '>
            Zapisz
          </SubmitButton>
        </Form>
      )}
    </Formik>
  );
};

export default LicensorForm;
