import { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';

import ContentContainer from 'components/layout/ContentContainer';
import FilterPanel from 'components/filters/FilterPanel';

import useInfiniteScroll from 'hooks/useInfiniteScroll';
import { DataViewMode } from 'types/globalTypes';
import { IDigitalRelease } from 'types/digitalReleaseTypes';
import digitalReleasesService from 'services/digitalReleaseService';

import DigitalReleasesList from './tiles/DigitalReleaseList';
import DigitalReleaseTable from './DigitalReleaseTable';
import DigitalReleaseTopbar from './DigitalReleaseTopbar';
import { useTranslation } from 'react-i18next';

const filters = [
  {
    name: 'Aktywny',
    values: ['Tak', 'Nie'],
  },
  {
    name: 'Sample',
    values: ['Val1', 'Val2', 'Val3'],
  },
];

const DigitalReleases: React.FC = () => {
  const { t } = useTranslation();
  const viewMode = useSelector((state: RootState) => state.ui.dataViewModes.digitalRelease);
  const [queryString, setQueryString] = useState('');
  const {
    items: digitalReleases,
    loading,
    containerRef,
    lastItemRef,
  } = useInfiniteScroll<IDigitalRelease>(digitalReleasesService.getAll, queryString);

  const handleQueryChange = (value: string) => {
    setQueryString(value);
  };

  return (
    <ContentContainer
      title={t('digitalRelease.title.list')}
      TopBar={<DigitalReleaseTopbar handleQueryChange={handleQueryChange} />}
    >
      <FilterPanel filters={filters} />

      {viewMode === DataViewMode.table ? (
        <DigitalReleaseTable
          digitalReleases={digitalReleases}
          containerRef={containerRef}
          lastItemRef={lastItemRef}
          isDataLoading={loading}
        />
      ) : (
        <DigitalReleasesList
          digitalReleases={digitalReleases}
          containerRef={containerRef}
          lastItemRef={lastItemRef}
          isDataLoading={loading}
        />
      )}
    </ContentContainer>
  );
};

export default DigitalReleases;
