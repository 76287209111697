import { IArtist } from '../../../types/artistTypes';
import { iTunesUrl, spotifyUrl } from '../../../utils/constants/constants';
import SpotifyIco from '../../../assets/icons/lists/spotify.svg';
import ITunesIco from '../../../assets/icons/lists/itunes.svg';

interface IProps {
  artist: IArtist;
}

const ArtistProfileCell = ({ artist }: IProps) => {
  return (
    <div className='flex justify-center items-center gap-x-4'>
      {/* {artist.spotifyId && ( */}
      <a target='__blank' href={spotifyUrl + artist.spotifyId}>
        <img src={SpotifyIco} alt='' />
      </a>
      {/* )} */}
      {/* {artist.iTunesId && ( */}
      <a target='__blank' href={iTunesUrl + artist.iTunesId}>
        <img src={ITunesIco} alt='' />
      </a>
      {/* )} */}
      {/* {artist.isni && ( */}
      <a target='__blank' className='opacity-80' href={iTunesUrl + artist.isni}>
        ISNI
      </a>
      {/* )} */}
    </div>
  );
};

export default ArtistProfileCell;
