import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/store';

import ContentContainer from 'components/layout/ContentContainer';
import InfoBox from 'components/common/boxes/InfoBox/InfoBox';
import InfoBoxPlaceholder from 'components/common/boxes/InfoBox/InfoBoxPlaceholder';

import { ArtistLabels, IArtist } from 'types/artistTypes';
import { iTunesUrl } from 'utils/constants/constants';

import Placeholder from 'assets/images/placeholder.png';
import { getArtistDetail, getPresspack } from 'store/actions/artistActions';

import ArtistDetailTopbar from './ArtistDetailTopbar';
import ArtistDetailTabs from './tabs/ArtistDetailTabs';

const ArtistDetail: React.FC = () => {
  const { detail }: { detail: IArtist } = useSelector((state: RootState) => state.artist);

  const dispatch = useDispatch();

  const { id } = useParams<{ id: string }>();

  const fetchArtistData = useCallback(async () => {
    await dispatch(getArtistDetail(id));
    await dispatch(getPresspack(id));
  }, [dispatch, id]);

  useEffect(() => {
    fetchArtistData();
  }, [fetchArtistData]);

  if (!detail) {
    return <InfoBoxPlaceholder />;
  }

  return (
    <ContentContainer title={detail.name} TopBar={<ArtistDetailTopbar artist={detail} />}>
      <div className='flex flex-col'>
        <InfoBox className='bg-white bg-opacity-60 p-18'>
          <InfoBox.Image src={Placeholder} />

          <InfoBox.Items>
            <InfoBox.InfoItem label={ArtistLabels.firstName} value={detail.firstName} />
            <InfoBox.InfoItem label={ArtistLabels.phoneNumber} value={detail.phoneNumber} />
            <InfoBox.InfoItem label={ArtistLabels.isni} value={detail.isni} />

            <InfoBox.InfoItem label={ArtistLabels.lastName} value={detail.lastName} />
            <InfoBox.InfoItem label={ArtistLabels.email} value={detail.email} />
            <InfoBox.LinkItem
              label={ArtistLabels.spotifyId}
              value={detail.spotifyId}
              href={iTunesUrl + detail.spotifyId}
              target='_blank'
            />

            <InfoBox.InfoItem label={ArtistLabels.type} value={detail.isBand ? 'Zespół' : 'Artysta'} />
            <InfoBox.InfoItem />
            <InfoBox.LinkItem
              label={ArtistLabels.iTunesId}
              value={detail.iTunesId}
              href={iTunesUrl + detail.iTunesId}
              target='_blank'
            />
          </InfoBox.Items>
        </InfoBox>

        <ArtistDetailTabs />
      </div>
    </ContentContainer>
  );
};

export default ArtistDetail;
